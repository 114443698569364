import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Fab,
  Grid,
  Toolbar
} from '@material-ui/core'
import { Box, Paper } from '@material-ui/core'
import { DateTimeFormatter, Yup, components, useServices } from 'cng-web-lib'
import React, { useEffect, useRef, useState } from 'react'

import Api from 'src/views/freightbooking/shared/api'
import SIApi from 'src/views/shippinginstruction/shared/api'
import SiApiParser from 'src/views/shippinginstruction/shared/apiParser'
import SIListComponent from '../components/SIListComponent'
import BookingListNoResult from 'src/views/freightbooking/components/BookingListNoResult'
import SIButtonTabComponent from '../components/SIButtonTabComponent'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import SIFilterPanelComponent from '../components/SIFilterPanelComponent'
import FreeTextSearchComponent from 'src/views/shippinginstruction/components/FreeTextSearchComponent'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { LensTwoTone } from '@material-ui/icons'
import { Paperclip } from 'react-feather'
import ResetFilterComponent from 'src/views/freightbooking/components/ResetFilterComponent'
import ScrollTop from 'src/views/freightbooking/components/ScrollTop'
import makeValidationSchema from 'src/views/freightbooking/shared/MakeValidationSchema'
import searchScheduleApiUrls from 'src/apiUrls/SearchScheduleApiUrls'
import { useLocation } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import TopBarSearchComponent from '../components/TopBarSearchComponent'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import CreateNewSITabButtonAndSortComponent from '../components/CreateNewSITabButtonAndSortComponent'
import Utils from 'src/views/common/utils/Utils'
import * as CalistaConstant from 'src/constants/CalistaConstant'
import ApiParser from 'src/views/freightbooking/shared/apiParser'

const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: { CngTextField, CngDateField }
  },
  CngGridItem
} = components

const initialValues = Object.freeze({
  dateRange: 'all',
  pendingClicked: true,
  filterText: '',
  sortCode: '',
  shipmentCount: 0,
  draftsCount: 0,
  statusCodes: [],
  status: {},
  partyIds: [],
  carriers: {},
  bls: {},
  showArchived: true,
  showDirectSI: false,
  statusTabChanged: true,
  blAppTypeCodes: ['SI_SB', 'SI_BL']
})

function FormFields(props) {
  //move this from BookingTypeComponent to share with Cargo component
  const { disabled, showNotification, shouldHideMap } = props
  const { fetchRecords } = useServices()
  // const location = useLocation()
  // const { submitForm } = useFormikContext()
  // const [statusCodesField, , { setValue: setStatusCodesField }] =
  //   useField('statusCodes')
  const [blAppTypeCodesField, , { setValue: setBlAppTypeCodesField }] =
    useField('blAppTypeCodes')
  const [dateRangeField, , { setValue: setDateRangeField }] =
    useField('dateRange')
  const [statusField, , { setValue: setStatusField }] = useField('status')
  const [sortCodeField, , { setValue: setSortCodeField }] = useField('sortCode')
  const [, , { setValue: setSortByListField }] = useField('sortByList')
  const [, , { setValue: setShipmentClickedField }] =
    useField('shipmentClicked')
  const [pendingClickedField, , { setValue: setPendingClickedField }] =
    useField('pendingClicked')
  const [filterTextField, , { setValue: setFilterTextField }] =
    useField('filterText')

  const [carriersField, , { setValue: setCarriersField }] = useField('carriers')
  const [showArchivedField, , { setValue: setShowArchivedField }] =
    useField('showArchived')
  const [showDirectSIField, , { setValue: setShowDirectSIField }] =
    useField('showDirectSI')
  const [partyIdsField, , { setValue: setPartyIdsField }] = useField('partyIds')
  const [statusTabChangedField, , { setValue: setStatusTabChangedField }] =
    useField('statusTabChanged')
  const [blsField, , { setValue: setBlsField }] = useField('bls')
  //const [bls, setBls] = useState([])

  const defaultDateRange = 'all' //default value to query via API
  const VALUE_ALL = 'ALL'
  const defaultShowArchived = true
  const defaultShowDirectSI = false

  const [dateRange, setDateRange] = useState()
  const [sortByList, setSortByList] = useState()
  const [filterText, setFilterText] = useState()
  const [siList, setSiList] = useState([])
  //const [originalBookingList, setOriginalBookingList] = useState([])
  const [showArchived, setShowArchived] = useState()
  const [showDirectSI, setShowDirectSI] = useState()
  const [loading, setLoading] = useState(true)
  const [shownCount, setShownCount] = useState(0)
  const translatedTextsObject = ShippingInstructionTranslationText()

  const clickShipment = () => {
    console.log('clickShipment')
    //in draft tab, click shipment ,tab will switch to "Shipment"
    props.setShipmentClicked(true)
    setStatusTabChangedField(true)
  }

  const clickDrafts = () => {
    console.log('clickDrafts')
    //in draft tab, click draft, nothing will happen
  }

  const handleSortByChange = (e) => {
    console.log('handleSortByChange')

    if (e.target.value != null && e.target.value != 0) {
      setSortCodeField(e.target.value)
      setStatusTabChangedField(false)

      manualRefresh()
    }
  }

  const handelFreeTextSearch = () => {
    setStatusTabChangedField(true)
    manualRefresh()
  }

  const handleClearFiltreAndTextButtonClick = () => {
    console.log('handleClearFiltreAndTextButtonClick')

    setFilterText('')
    setFilterTextField('')
    handleClearAllButtonClick()
  }
  const handleClearAllButtonClick = () => {
    console.log('handleClearAllButtonClick')

    setDateRange(defaultDateRange)
    setDateRangeField(defaultDateRange)
    let newStatus = [...status]
    newStatus = newStatus.map((item) => ({
      ...item,
      checked: true
    }))

    console.log(newStatus)
    setStatus(newStatus)
    setStatusField(newStatus)

    setBlsField([])
    setBlAppTypeCodesField(initialValues.blAppTypeCodes)

    let newCarriers = [...carriers]
    newCarriers = newCarriers.map((item) => ({
      ...item,
      checked: true
    }))

    setCarriers(newCarriers)
    setCarriersField(newCarriers)

    setShowArchivedField(defaultShowArchived)
    setShowArchived(defaultShowArchived)

    setShowDirectSIField(defaultShowDirectSI)
    setShowDirectSI(defaultShowDirectSI)

    setStatusTabChangedField(true) //force carrier list load from api
    manualRefresh()
  }

  const handleFilterTextChange = (e) => {
    console.log(e.target)
    setFilterText(e.target.value)
    setFilterTextField(e.target.value)

    // submitForm()
  }

  const onStatusCheckBoxChange =
    (index) =>
    ({ target: { checked } }) => {
      console.log('handleStatusCheckBoxChange')
      // let newStatus = [...status]
      // const currentStatus = status[index]
      // if (currentStatus.statusDesc !== VALUE_ALL) {
      //   const allStatus = status[status.length - 1]

      //   newStatus[index] = { ...currentStatus, checked: !currentStatus.checked }
      //   newStatus[status.length - 1] = { ...allStatus, checked: false }
      // } else {
      //   newStatus = newStatus.map((item) => ({
      //     ...item,
      //     checked: !currentStatus.checked
      //   }))
      // }

      // setStatus(newStatus)
      // setStatusField(newStatus)
      // console.log(newStatus)

      // //get new status code and list
      // let newStatusCode = getNewStatuCode(newStatus)
      // console.log(newStatusCode)

      // setStatusCodesField(newStatusCode)
      // setStatusTabChangedField(true) //force carrier list load from api
      // manualRefresh()
    }

  function getNewStatuCode(newStatus) {
    let temp = []
    newStatus
      .filter((v) => v.checked == true)
      .map((v) => temp.push(v.statusCode))

    return temp
  }

  const showArchivedChange = (e) => {
    console.log('showArchivedChange')
    console.log(e.target.checked)
    setShowArchivedField(e.target.checked)
    setStatusTabChangedField(true) //force carrier list load from api
    manualRefresh()
  }

  const dateRangeChange = (e) => {
    console.log(e.target.defaultValue)
    //setStatusTabChangedField(true)
    setDateRange(e.target.defaultValue)
    setDateRangeField(e.target.defaultValue)

    manualRefresh()
  }

  const showDirectSIChange = (e) => {
    console.log('showDirectSIChange')
    console.log(e.target.checked)
    setShowDirectSIField(e.target.checked)
    setStatusTabChangedField(true)
    manualRefresh()
  }

  const onBLCheckBoxChange =
    (index) =>
    ({ target: { checked } }) => {
      console.log('onBLCheckBoxChange ' + index)
      let newBls = [...bls]
      const bl = bls[index]
      //console.log(bl.partyId)
      if (bl.statusDesc !== VALUE_ALL) {
        const allBl = bls[bls.length - 1]

        newBls[index] = { ...bl, checked: !bl.checked }
        newBls[bls.length - 1] = { ...allBl, checked: false }
      } else {
        newBls = newBls.map((item) => ({
          ...item,
          checked: !bl.checked
        }))
      }

      setBls(newBls)
      setBlsField(newBls)
      console.log()

      let newBlList = getNewBls(newBls)
      console.log(newBlList)

      setBlAppTypeCodesField(newBlList)

      manualRefresh()
    }

  function getNewBls(newBls) {
    let temp = []
    newBls
      .filter((v) => v.checked == true)
      .map((v) =>
        // console.log(v.statusCode)
        // let temp2 = v.statusCode
        temp.push(v.statusCode)
      )

    return temp
  }

  const onCarrierCheckBoxChange =
    (index) =>
    ({ target: { checked } }) => {
      console.log('onCarrierCheckBoxChange ' + index)
      let newCarriers = [...carriers]
      const carrier = carriers[index]
      console.log(carrier)
      if (carrier.nameOth !== VALUE_ALL) {
        const allCarrier = carriers[carriers.length - 1]

        newCarriers[index] = { ...carrier, checked: !carrier.checked }
        newCarriers[carriers.length - 1] = { ...allCarrier, checked: false }
      } else {
        newCarriers = newCarriers.map((item) => ({
          ...item,
          checked: !carrier.checked
        }))
      }

      setCarriers(newCarriers)
      setCarriersField(newCarriers)

      let temp = []
      newCarriers
        .filter((v) => v.checked == true)
        .map((v) => temp.push(v.carrierPartyId))

      console.log(temp)

      setPartyIdsField(temp)
      setStatusTabChangedField(false)

      manualRefresh()
    }

  let searchCriteria = {
    filterText: filterTextField.value,
    dateRange: dateRangeField.value,
    statusCodes: [5201],
    showArchived: showArchivedField.value,
    showDirectSIOnly: showDirectSIField.value,
    blAppTypeCodes: blAppTypeCodesField.value,
    partyIds: partyIdsField.value,
    carrierCodes: partyIdsField.value,
    sortCode: Utils.isEmptyString(sortCodeField.value) ? 1 : sortCodeField.value
  }

  let criteriaForCount = {
    filterText: '',
    dateRange: defaultDateRange,
    statusCodes: [CalistaConstant.BOOKING_STATUS_APPROVED],
    showArchived: defaultShowArchived,
    partyIds: initialValues.partyIds,
    sortCode: 1
  }

  const [carriers, setCarriers] = useState([])
  const [bls, setBls] = useState([])
  const [status, setStatus] = useState([])
  const [pendingStatusList, setPendingStatusList] = useState([])
  const [processedStatusList, setProcessedStatusList] = useState([])
  const [shipmentCount, setShipmentCount] = useState(0)
  const [draftsCount, setDraftsCount] = useState(0)
  const [shipmentClicked, setShipmentClicked] = useState()
  //const [pendingCount, setPendingCount] = useState(0)
  //const [processedCount, setProcessedCount] = useState(0)
  const [pendingClicked, setPendingClicked] = useState()
  const [loadingCriteria, setLoadingCriteria] = useState()
  const [pageError, setPageError] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const manualRefresh = () => {
    setRefresh(!refresh)
    setLoading(true)
    setSiList([])
    console.log(partyIdsField.value)
    setShownCount(0)
  }

  useEffect(() => {
    // setPendingClickedField(pendingClickedField.value)

    setSortCodeField(
      sortCodeField.value == null || sortCodeField.value == ''
        ? 1
        : sortCodeField.value
    )

    // setShowArchived(
    //   location.state == null ? defaultShowArchived : location.state.showArchived
    // )

    if (sortByList == null) {
      //fetch sort code when the page is first loaded, the result will remain static
      getSortingType()
    }

    //get status code and count
    //fetchStatusCodeAndCountFromAPI()

    fetchShipmentCountFromAPI()
    fetchDraftsCountFromAPI()

    //get party id based on status code, date range and sort code
  }, [refresh])

  function fetchDraftsCountFromAPI() {
    SIApi.fetchStatusCodeAndCount(fetchRecords, populateDraftsCount, onCountError)
  }

  function onCountError(error){
    console.log(error)
    fetchBLApplicationTypeFromAPI()
    fetchPartyIdFromAPI()
  }

  function populateDraftsCount(dat) {
    // const data = SiApiParser.parseDraftCount(dat)
    console.log('populateDraftsCount', dat)
    setDraftsCount(dat.pendingSubmissionCount)
    fetchBLApplicationTypeFromAPI()
    fetchPartyIdFromAPI()
  }

  function fetchShipmentCountFromAPI() {
    Api.fetchPartyId(
      fetchRecords,
      criteriaForCount,
      populatePartyIdForCount,
      onError
    )
  }

  function populatePartyIdForCount(dat) {
    let FromApi = ApiParser.parsePartyId(dat)
    if (FromApi[0] !== undefined) {
      let newRecord = { ...FromApi[0] }
      newRecord.nameOth = 'ALL'
      newRecord.bookingCount = FromApi.reduce((a, b) => a + b.bookingCount, 0)
      FromApi = [...FromApi, newRecord]

      console.log(FromApi)

      let partyIdsForSearch = getPartyIdForCount(dat.content)
      console.log(partyIdsForSearch)
      getShipmentCount()
    }
  }

  function getShipmentCount() {
    Api.fetchBookingList(
      fetchRecords,
      criteriaForCount,
      populateShipmentCount,
      onError
    )
  }

  function getPartyIdForCount(carrierList) {
    console.log('getPartyIdForCount')
    let partyIdss = []

    console.log('loading party id from api')

    carrierList.map((v) => {
      console.log(v.partyId)
      partyIdss.push(v.partyId)
    })

    criteriaForCount.partyIds = partyIdss

    return partyIdss
  }

  function populateShipmentCount(dat) {
    console.log('populateShipmentCount', dat)
    setShipmentCount(dat.totalElements)
  }

  function fetchBLApplicationTypeFromAPI() {
    SIApi.fetchBLApplicationType(
      fetchRecords,
      searchCriteria,
      populateBLApplicationType,
      onError
    )
  }

  function populateBLApplicationType(dat) {
    console.log(dat)
    let BLList = SiApiParser.parseBlApplicationType(dat)
    console.log(BLList)
    setBls(Utils.isEmptyObject(blsField.value) ? BLList : blsField.value)
  }

  function populatePartyId(dat) {
    let FromApi = SiApiParser.parsePartyId(dat)
    console.log(FromApi)
    if (FromApi[0] !== undefined) {
      FromApi.map((elem) => (elem.bookingCount = elem.shippingCount))
      let newRecord = { ...FromApi[0] }
      newRecord.nameOth = 'ALL'
      newRecord.bookingCount = FromApi.reduce((a, b) => a + b.shippingCount, 0)
      FromApi = [...FromApi, newRecord]

      console.log(FromApi)

      let partyIdsForSearch = getPartyIdForBookingList(dat)
      console.log(partyIdsForSearch)

      getShippingInfoList()
    } else {
      setLoading(false)
      setPartyIdsField(
        Utils.isEmptyString(partyIdsField.value) ? [] : partyIdsField.value
      )
    }

    if (reloadCarrierCheckBoxNeeded()) {
      setCarriers(FromApi)
      setCarriersField(FromApi)
    } else {
      setCarriers(carriersField.value)
      //  setCarriersField(location.state.carriers)
    }
  }
  function fetchPartyIdFromAPI() {
    SIApi.fetchPartyId(fetchRecords, searchCriteria, populatePartyId, onError)
  }

  function reloadCarrierCheckBoxNeeded() {
    return statusTabChangedField.value
  }

  /* function reloadBlCheckBoxNeeded() {

        return location.state == null || location.state.statusTabChanged
    }
 */
  function getPartyIdForBookingList(carrierList) {
    console.log('getPartyIdForBookingList')
    let partyIdss = []
    if (reloadCarrierCheckBoxNeeded()) {
      console.log('loading party id from api')

      carrierList.map((v) => {
        console.log(v.carrierPartyId)
        partyIdss.push(v.carrierPartyId)
      })

      //temp for booking list
      // searchCriteria.partyIds = partyIdss
      // searchCriteria.statusCodes = [5107]
      searchCriteria.carrierCodes = partyIdss
      setPartyIdsField(partyIdss)
    } else {
      console.log('loading party id from hisotry')
      setPartyIdsField(partyIdsField.value)
    }
    return partyIdss
  }

  function populateStatusCodeAndCount(dat) {
    let isHelpdesk = false;
    const data = SiApiParser.parseStatusCodeAndCount(dat, false, isHelpdesk);
    //setPendingCount(data.pendingCount)
    // setDraftsCount(data.pendingCount)
    // setPendingStatusCountField(data.pendingCount)
    // setProcessedStatusCountField(data.processedCount)
    // setShipmentCount(data.processedCount)
    // //setProcessedCount(data.processedCount)

    // setStatus(
    //   Utils.isEmptyObject(statusField.value)
    //     ? data.pendingList
    //     : statusField.value
    // )
    // setStatusCodesField(
    //   location.state == null ? pendingStatusCode : location.state.statusCodes
    // )
    // setStatusField(
    //   location.state == null ? data.pendingList : location.state.status
    // )

    // const BLList = [
    //   {
    //     baseFilterDTO: null,
    //     checked: true,
    //     customData: null,
    //     eligible: true,
    //     errorMessages: null,
    //     statusCode: 5130,
    //     statusDesc: 'Original B/L'
    //   },
    //   {
    //     baseFilterDTO: null,
    //     checked: true,
    //     customData: null,
    //     eligible: true,
    //     errorMessages: null,
    //     statusCode: 5131,
    //     statusDesc: 'Seaway Bill'
    //   },
    //   {
    //     baseFilterDTO: null,
    //     checked: true,
    //     customData: null,
    //     eligible: true,
    //     errorMessages: null,
    //     statusCode: 5132,
    //     statusDesc: 'Hazmat B/L'
    //   },
    //   {
    //     baseFilterDTO: null,
    //     checked: true,
    //     customData: null,
    //     eligible: true,
    //     errorMessages: null,
    //     statusCode: 5133,
    //     statusDesc: 'ALL'
    //   }
    // ]

    //setBls(Utils.isEmptyObject(blsField.value) ? BLList : blsField.value)

    // setPendingStatusList(data.pendingList)
    // setProcessedStatusList(data.processedList)
    // console.log(pendingClicked)
    //let statusCodes = pendingClicked ? pendingStatusCode : processedStatusCode
  }

  function fetchStatusCodeAndCountFromAPI() {
    Api.fetchStatusCodeAndCount(
      fetchRecords,
      populateStatusCodeAndCount,
      onError
    )
  }

  function populateSortingType(dat) {
    const list = ApiParser.parseSortingType(dat)
    console.log(list)
    setSortByList(list)
    setSortByListField(list)
  }

  /*     function getLeftCount() {
            let count = (pendingClicked ? pendingCount - shownCount : processedCount - shownCount)
            return count
        } */

  function getLeftCount() {
    let count = props.shipmentClicked
      ? shipmentCount - shownCount
      : draftsCount - shownCount
    console.log('clicked: ' + props.shipmentClicked)
    console.log('shown: ' + shownCount)
    console.log('shipment: ' + shipmentCount)
    console.log('draft: ' + draftsCount)
    console.log('count: ' + count)
    return count
  }

  function showNoResultPage() {
    return siList.length === 0 && getLeftCount() == 0 && !loading
  }

  function getSortingType() {
    Api.fetchSortingType(fetchRecords, populateSortingType, onError)
  }

  function onError(error) {
    //console.log('connection-request error', error.message)
    setPageError(true)
    setLoading(false)
  }

  function populateShippingInfoList(data) {
    console.log(data)
    // let temp = data.content
    // temp.map((m) => {
    //   m.bookingStatus = 5201
    //   m.bookStatus = 5201
    //   m.bookStatusDesc = 'Pending si Submission'
    // })
    // setBookingList(temp)
    setSiList(data.content)
    setLoading(false)
    setShownCount(data.totalElements)
  }

  function getShippingInfoList() {
    SIApi.fetchShippingInfoList(
      fetchRecords,
      searchCriteria,
      populateShippingInfoList,
      onError
    )
    setLoadingCriteria(searchCriteria)
  }

  /*     function getLeftCount() {
            let count = (pendingClicked ? pendingCount - shownCount : processedCount - shownCount)
            return count
        } */

  function showNoResultPage() {
    return siList.length == 0 && getLeftCount() == 0 && !loading
  }

  return (
    <Card className='page-content'>
      <Box>
        <Grid container xs={12} ms={12} justify='center'>
          <CngGridItem xs={9} ms={9}>
            <Card>
              <CardContent>
                <CngGridItem xs={12} ms={12}>
                  <CreateNewSITabButtonAndSortComponent.FormBody
                    firstTabLabel={translatedTextsObject.shipmentOverview}
                    secondTabLabel={translatedTextsObject.drafts}
                    firstTabCount={shipmentCount}
                    secondTabCount={draftsCount}
                    draftsCount={draftsCount}
                    shownCount={shownCount}
                    firstTabClicked={props.shipmentClicked}
                    firstTabOnClick={clickShipment}
                    secondTabOnClick={clickDrafts}
                    sortByList={sortByList}
                    handleSortByChange={handleSortByChange}
                  />
                  {/* <SIButtonTabComponent.FormBody
                    shipmentCount={shipmentCount}
                    draftsCount={draftsCount}
                    shownCount={shownCount}
                    pendingClicked={pendingClicked}
                    clickShipment={clickShipment}
                    clickDrafts={clickDrafts}
                    sortByList={sortByList}
                    handleSortByChange={handleSortByChange}
                    draft={true}
                  /> */}
                </CngGridItem>

                {
                  <CngGridItem xs={12} ms={12}>
                    <Box
                      style={{
                        display: showNoResultPage() ? 'inline' : 'none'
                      }}
                    >
                      no result
                    </Box>
                    <Box
                      style={{
                        display: !showNoResultPage() ? 'inline' : 'none'
                      }}
                    >
                      <SIListComponent.FormBody
                        draftSI
                        searchCriteria={loadingCriteria}
                        siList={siList}
                        showNotification={showNotification}
                        handleClearAllButtonClick={
                          handleClearFiltreAndTextButtonClick
                        }
                        leftCount={getLeftCount()}
                        refreshAction={manualRefresh}
                        setPageError={setPageError}
                      />
                    </Box>
                  </CngGridItem>
                }
              </CardContent>
            </Card>
          </CngGridItem>
          <CngGridItem xs={3} ms={3}>
            <Card>
              <CardContent>
                <Grid container xs={12} ms={12} spacing={2}>
                  <CngGridItem xs={12} ms={12}>
                    <FreeTextSearchComponent.FormBody
                      handleInputChange={handleFilterTextChange}
                      filterText={filterText}
                      SI={true}
                      handelFreeTextSearch={handelFreeTextSearch}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} ms={12}>
                    <SIFilterPanelComponent
                      handleClearAllButtonClick={handleClearAllButtonClick}
                      onStatusCheckBoxChange={onStatusCheckBoxChange}
                      showArchivedChange={showArchivedChange}
                      onCarrierCheckBoxChange={onCarrierCheckBoxChange}
                      onBLCheckBoxChange={onBLCheckBoxChange}
                      showDirectSIChange={showDirectSIChange}
                      dateRange={dateRangeField.value}
                      carriers={carriers}
                      bls={bls}
                      status={status}
                      showArchived={showArchived}
                      showDirectSI={showDirectSI}
                      draft={true}
                      dateRangeChange={dateRangeChange}
                    />
                  </CngGridItem>
                  <CngGridItem
                    xs={12}
                    sm={9}
                    shouldHide={loading ? false : true}
                  >
                    <CngBackdrop loading={loading} />
                  </CngGridItem>
                </Grid>
              </CardContent>
            </Card>
          </CngGridItem>
        </Grid>
      </Box>
      {/* <Box justifyContent="center">
                <Box>
                    <Toolbar id="back-to-top-anchor" />
                    <Grid container xs={12} ms={12} spacing={2} justify="center" style={{ backgroundColor: "red" }}>

                        <CngGridItem xs={12} ms={12} >
                        </CngGridItem>
                        <CngGridItem xs={9} ms={9} >
                            <Card>
                                <CardContent>
                                  
                                    <CngGridItem xs={12} ms={12} >
                                        <ButtonTabComponent.FormBody
                                            pendingCount={pendingCount}
                                            processedCount={processedCount}
                                            shownCount={shownCount}
                                            pendingClicked={pendingClicked}
                                            clickPending={clickPending}
                                            clickProcessed={clickProcessed}
                                            sortByList={sortByList}
                                            handleSortByChange={handleSortByChange} />
                                    </CngGridItem>

                                    <CngGridItem xs={12} ms={12} >
                                       
                                        <Box>
                                            <BookingListComponent.FormBody
                                                searchCriteria={loadingCriteria}
                                                bookingList={bookingList}
                                                showNotification={showNotification}
                                                handleClearAllButtonClick={handleClearFiltreAndTextButtonClick}
                                                leftCount={getLeftCount()} />
                                        </Box>
                                    </CngGridItem>

                                </CardContent>
                            </Card>
                           
                        </CngGridItem>

                        <CngGridItem sm={3} xs={12} >
                            <Grid container xs={12} ms={12} spacing={2}>
                                <CngGridItem xs={12} ms={12} >
                                    <FreeTextSearchComponent.FormBody
                                        handleInputChange={handleFilterTextChange}
                                        filterText={filterText}
                                    />
                                </CngGridItem>
                                <CngGridItem xs={12} ms={12} >
                                    <FilterPanelComponent
                                        handleClearAllButtonClick={handleClearAllButtonClick}
                                        onStatusCheckBoxChange={onStatusCheckBoxChange}
                                        showArchivedChange={showArchivedChange}
                                        onCarrierCheckBoxChange={onCarrierCheckBoxChange}
                                        dateRange={location.state == null ? defaultDateRange : location.state.dateRange}
                                        carriers={carriers}
                                        status={status}
                                        showArchived={showArchived}
                                    />
                                </CngGridItem>
                                <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
                                    <CngBackdrop loading={loading} />
                                </CngGridItem>

                            </Grid>
                        </CngGridItem>
                        <ScrollTop>
                            <Fab color="secondary" size="small" aria-label="scroll back to top">
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </Grid>
                </Box>
            </Box> */}
    </Card>
  )
}

const DraftSearchFormProperties = Object.freeze({
  initialValues: initialValues,
  FormFields: FormFields
})

export default DraftSearchFormProperties
