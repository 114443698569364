import { useHistory, useParams, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import ViewBookingTypeComponent from 'src/views/freightbooking/components/ViewBookingTypeComponent'
import ViewScheduleComponent from 'src/views/freightbooking/components/ViewScheduleComponent'
import ShipmentSearchFormProperties from './ShipmentSearchFormProperties'
import DraftSearchFormProperties from './DraftSearchFormProperties'

import {
  Yup,
  components,
  DateTimeFormatter,
  useServices,
  constants
} from 'cng-web-lib'

const { CodeMaintenanceType } = constants


const DEFAULT_INITIAL_VALUES = Object.freeze({
  ...ShipmentSearchFormProperties.initialValues,
  ...DraftSearchFormProperties.initialValues
})
const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
  //makeValidationSchema: makeValidationSchema
}

function FormFields(props) {
  const location = useLocation()
  const [shipmentClicked, setShipmentClicked] = useState(true)

  useEffect(() => {
    console.log(location)
    console.log('shipmentClickedxxx: ' + shipmentClicked)
    if (location.state != null && location.state.shipmentClicked != null) {
      console.log('setshipmentClickedxxx')
      setShipmentClicked(location.state.shipmentClicked)
    }
  }, [])

  return (
    <div>
      {shipmentClicked ? (
        <ShipmentSearchFormProperties.FormFields
          {...props}
          shipmentClicked={shipmentClicked}
          setShipmentClicked={setShipmentClicked}
        />
      ) : (
        <DraftSearchFormProperties.FormFields
          {...props}
          shipmentClicked={shipmentClicked}
          setShipmentClicked={setShipmentClicked}
        />
      )}
    </div>
  )
}

const PanelSection = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
})

export default PanelSection
