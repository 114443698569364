import { useHistory, useParams, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import ViewBookingTypeComponent from 'src/views/freightbooking/components/ViewBookingTypeComponent'
import ViewScheduleComponent from 'src/views/freightbooking/components/ViewScheduleComponent'
import CreateSIReviewApproved from './CreateSIReviewApproved'


import Api from 'src/views/freightbooking/shared/api'
import ApiParser from 'src/views/freightbooking/shared/apiParser'
import { Yup, components, DateTimeFormatter, useServices, constants } from 'cng-web-lib'
import HeaderBarTop from 'src/views/freightbooking/components/HeaderBarTop'

import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import CodeMasterApi from 'src/views/common/api/CodeMasterApi'
import SharedServiceApi from 'src/views/common/api/SharedServiceApi'
import CarrierApi from 'src/views/common/api/CarrierApi'
const {
  CodeMaintenanceType
} = constants
const {
  form: { CngViewForm }
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  ...ViewBookingTypeComponent.initialValues,
  ...ViewScheduleComponent.initialValues,
})
const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  //makeValidationSchema: makeValidationSchema
}

function FormFields(props) {
  const location = useLocation()
  const { fetchRecords } = useServices()
  //const bookStatus = location.state.bookStatus
  const [auditList, setAuditList] = useState([])
  const [userCriteria, setUserCriteria] = useState([])
  
  //console.log('BodySection bookStatus: ' + bookStatus)
  const [booking, setBooking] = useState({})
  const [ports, setPorts] = useState([])
  const bookingRequest = {
    bookingId: location.state.bookingId,
    dockey: location.state.dockey
  }
  useEffect(() => {
    Api.fetchBookingDetailsById(fetchRecords, bookingRequest, populateBookingData)

    CodeMaintainanceApi.fetchCodeMaintainance(fetchRecords, CodeMaintenanceType.PORT,
      [], false, onLoadPortSuccess)

    CodeMaintainanceApi.fetchCodeMaintainance(fetchRecords, CodeMaintenanceType.PACKAGE_UOM,
      [], false, onLoadPackageUomSuccess)

    CodeMaintainanceApi.fetchCodeMaintainance(fetchRecords, CodeMaintenanceType.UOM,
      [], false, onLoadUomSuccess)

    CodeMasterApi.fetchCodeMaster(fetchRecords, 'CAL_CONT_STATUS', false, onLoadCodeMasterSuccess)

    SharedServiceApi.fetchContainerType(fetchRecords, false, onContainerTypeLoadSuccess)

    CarrierApi.fetchCarrier(fetchRecords, onLoadCarrierSuccess)


  }, [])

  // function onAuditTrailListingLoadSuccess(data){
  //     location.state.ContainerTypes = datap
  // }
  function onContainerTypeLoadSuccess(data) {
    location.state.ContainerTypes = data
  }

  function populateBookingData(data) {
    data.bookStatusDesc = location.state.bookStatusDesc
    setBooking(data)
    if (data.freightBookingId) {
      Api.fetchAuditTrailListing(fetchRecords, data.freightBookingId, onAuditTrailListingLoadSuccess)
    }
  }
  function onAuditTrailListingLoadSuccess(data) {
    data.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
    setAuditList(data)

    let userList = []
    data.forEach((val)=>{
      userList.push(val.userId)
    })

    setUserCriteria(userList)
  }

  function onLoadCarrierSuccess(data) {
    location.state.Carriers = data
  }
  function onLoadCodeMasterSuccess(data) {
    location.state.CAL_CONT_STATUS = data
  }
  function onLoadPackageUomSuccess(data) {
    location.state.PACKAGE_UOM = data
  }
  function onLoadUomSuccess(data) {
    location.state.UOM = data
  }
  function onLoadPortSuccess(data) {
    console.log("onLoadPortSuccess")
    console.log("data: ", data)
    setPorts(data)
  }
  return (
    <div>
      <CreateSIReviewApproved.FormFields booking={booking} ports={ports} auditList={auditList} userCriteria={userCriteria} {...props} />
    </div>
  )
}

const BodySection = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
})


export default BodySection
