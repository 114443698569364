import { useHistory, useParams } from 'react-router-dom'

import React from 'react'
import BodySection from './BodySection'
import { components } from 'cng-web-lib'

const {
  form: { CngViewForm }
} = components

function CreateSIViewSIDraftPage(props) {
  const { id, dockey } = useParams()
  const history = useHistory()
  const { showNotification } = props

  function onSubmit(data) {
    console.log('submit edit')
  }

  return (

    // use CngViewForm here for customizable button set
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <BodySection.FormFields
          disabled={false}
          showNotification={showNotification}
          shippingInfoId = {id}
          freightBookingId = {dockey}
          {...props}
        />
      }
      formikProps={{
        ...BodySection.formikProps,
        onSubmit: onSubmit
      }}
    />

  )
}

export default CreateSIViewSIDraftPage
