import * as calistaConstant from 'src/constants/CalistaConstant'

import { Box, Paper } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'

import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import DraftSIButtonComponent from '../newsi/DraftSIButtonComponent'
import SubmittedSIButtonComponent from './SubmittedSIButtonComponent'
import ViewBookingTypeComponent from 'src/views/shippinginstruction/components/ViewBookingTypeComponent'
import ViewSIUploadDocumentComponent from 'src/views/shippinginstruction/components/ViewSIUploadDocumentComponent'
import ReviewSISuccessMessageComponent from 'src/views/shippinginstruction/components/ReviewSISuccessMessageComponent'
import ViewScheduleComponent from 'src/views/shippinginstruction/components/ViewScheduleComponent'
import ViewShipmentComponent from 'src/views/shippinginstruction/components/ViewShipmentComponent'
import ViewSIContainerComponent from 'src/views/shippinginstruction/components/ViewSIContainerComponent'
import ViewSICargoComponent from 'src/views/shippinginstruction/components/ViewSICargoComponent'
import makeValidationSchema from 'src/views/freightbooking/shared/MakeValidationSchema'
import moment from 'moment'
import Utils from 'src/views/freightbooking/shared/Utils'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import CommonUtils from 'src/views/common/utils/Utils'
import ViewSIDgCargoComponent from 'src/views/shippinginstruction/components/ViewSIDgCargoComponent'
import ViewContactComponent from 'src/views/shippinginstruction/components/ViewContactComponent'
import ViewPaymentComponent from 'src/views/shippinginstruction/components/ViewPaymentComponent'
import ViewDocumentComponent from 'src/views/freightbooking/components/ViewDocumentComponent'
import GeneralStepperComponent from 'src/views/common/ui/GeneralStepperComponent'
import pathMap from 'src/paths/PathMap_ShippingInstruction'
import MySIReviewSIButtonComponent from 'src/views/shippinginstruction/mysi/MySIReviewSIButtonComponent'
import ManageSIReviewSIButtonComponent from 'src/views/shippinginstruction/managesi/ManageSIReviewSIButtonComponent'
import ShippingInstructionApiUrls from 'src/apiUrls/ShippingInstructionApiUrls.js'
import SIParticularsControlTotalComponent from 'src/views/shippinginstruction/components/SIParticularsControlTotalComponent'
import Api from 'src/views/shippinginstruction/shared/api'
import QuickScroll from 'src/components/iconbutton/QuickScroll'

const DEFAULT_INITIAL_VALUES = Object.freeze({
  bookingId: '',
  setFreightBookingId: '',
  bookingStatus: '',
  ...ViewBookingTypeComponent.initialValues,
  ...ViewScheduleComponent.initialValues,
  isFirstAction: false
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

const {
  table: { useDefaultNotification },
  CngGridItem
} = components

function FormFields({ disabled, showNotification, shouldHideMap }) {
  const translatedTextsObject = FreightBookingTranslationText()
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  // const calistaConstant = CalistaConstant()
  //get data from useHistory

  const { createRecord } = useServices()
  const history = useHistory()
  const location = useLocation()
  const data = location.state.data
  console.log('LOCATION', location.state)
  const containers = location.state.data.shippingInfoContainerBoxes || []
  const cargo = location.state.data.shippingInfoCargoes || []
  const dgCargo = location.state.data.shippingInfoDGCargoes || []
  const uploadedSIDocuments = location.state.data.shippingInfoUploadDTO || []
  const directSi = location.state.data.directSi || false
  const prevMenu = location.state.prevMenu
  const documents = getDocumentList()
  const [loading, setLoading] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [bookingStep, setBookingStep] = useState(1)
  const [siStatus, setSiStatus] = useState(0)
  const isSubmitted = location.state.isSubmitted || false
  const isSkipManagerApproval = location.state.data.skipManagerApproval || false
  const [successMsgTitle, setSuccessMsgTitle] = useState()
  const [successMsgContent, setSuccessMsgContent] = useState()
  const [successMsgDate, setSuccessMsgDate] = useState()

  function getDocumentList() {
    if (location.state.data.siSubmitted) {
      //step 3 , booking document only
      return location.state.data.shippingInfoDocuments == null
        ? []
        : location.state.data.shippingInfoDocuments
    } else {
      //step 2 , combine document and pending document list
      let pendingList =
        location.state.data.pendingShippingInfoDocuments == null
          ? []
          : processPendingDocList(
            location.state.data.pendingShippingInfoDocuments
          )
      return location.state.data.shippingInfoDocuments == null
        ? pendingList
        : location.state.data.shippingInfoDocuments.concat(pendingList)
    }
  }

  function processPendingDocList(pendingDocList) {
    console.log('testdoc, pendingDocList start', pendingDocList)
    pendingDocList.forEach((doc) => {
      doc.docType = doc.fields[0].value
      doc.description = doc.fields[1].value
    })

    console.log('testdoc, pendingDocList end', pendingDocList)
    return pendingDocList
  }
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  const onEdit = () => {
    switch (prevMenu) {
      case SIUtils.Menus.newSI:
        history.push({
          pathname: pathMap.CREATE_SI_NEW_SI,
          state: { shippingInfo: data, prevMenu: prevMenu, fromSIReview: true } //for new si, the parameter "fromSIReview" determines if the cargo/container list if loaded from booking or from si data
        })
        break

      case SIUtils.Menus.mySI:
        history.push({
          pathname: pathMap.MY_SI_MANAGE_SI,
          state: { shippingInfo: data, prevMenu: prevMenu }
        })
        break

      case SIUtils.Menus.draftSI:
        history.push({
          pathname: pathMap.CREATE_SI_UPDATE_SI_DRAFT,
          state: { shippingInfo: data, prevMenu }
        })
        break

      case SIUtils.Menus.manageSI:
        history.push({
          pathname: pathMap.MANAGE_SI_MANAGE_SI,
          state: { shippingInfo: data, prevMenu }
        })
        break

      default:
        break
    }
  }

  const onSaveDraft = () => {
    setLoading(true)
    console.log('saveDraft')

    if (
      data.pendingShippingInfoDocuments &&
      !Utils.isEmpty(data.pendingShippingInfoDocuments)
    ) {
      uploadDocumentAndProceed(false)
    } else {
      proceedSecondAction(data)
    }
  }

  function proceedSecondAction(newSIData) {
    console.log('proceedSecondAction', newSIData)
    let url = getUrlForSecondAction()
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
        setLoading(false)
      } else {
        // showSuccessNotification(
        //   `${siTranslatedTextsObject.draftSuccessMsg} ${response.freightBookingId}`
        // )

        showSuccessNotificationBasedOnCurrentAction(url, response)

        newSIData.siSubmitted = true
        newSIData.siStatus = response.siStatus
        newSIData.updatedDate = response.updatedDate
        newSIData.shippingInfoId = response.shippingInfoId

        setLoading(false)
        navigateToSubmittedPage(SIUtils.expandBookingToShippingInfo(newSIData))
      }
      setLoading(false)
    }

    createRecord.execute(
      url, //FreightBookingApiUrls.SAVE_DRAFT,
      newSIData,
      onSuccess,
      onError
    )
  }

  function onConfirmSI() {
    //alert('confirm si')
    //SIUtils.convertDate(data)
    setLoading(true)
    console.log('confirm si')

    // if (
    //   data.pendingShippingInfoDocuments &&
    //   !Utils.isEmpty(data.pendingShippingInfoDocuments)
    // ) {
    //   uploadDocumentAndProceed(true)
    // } else {
    proceedFirstAction(data)
    //}
  }

  function onConfirmUpdateSI() {
    //alert('onConfirmUpdateSI')
    //SIUtils.convertDate(data)
    setLoading(true)
    console.log('confirm and update si')

    if (
      data.pendingShippingInfoDocuments &&
      !Utils.isEmpty(data.pendingShippingInfoDocuments)
    ) {
      uploadDocumentAndProceed(false)
    } else {
      proceedSecondAction(data)
    }
  }

  function onSubmitSI() {
    //submit si from pending resubmission or pending carrier confirmation - will not update document

    setLoading(true)
    console.log('submit si ??', data)
    // setFirstActionField(true)

    proceedFirstAction({ shippingInfoId: data.shippingInfoId, freightBookingId: data.freightBookingId })
  }

  function onSubmitDraftSI() {
    //submit si from draft or null SI - need to update document if necessary

    setLoading(true)
    console.log('submit draft si')
    // setFirstActionField(true)
    if (
      data.pendingShippingInfoDocuments &&
      !Utils.isEmpty(data.pendingShippingInfoDocuments)
    ) {
      uploadDocumentAndProceed(true)
    } else {
      proceedFirstAction(data)
    }
  }

  function uploadDocumentAndProceed(isFirstAction) {
    setLoading(true)
    var formData = new FormData()
    console.log(data.pendingShippingInfoDocuments)

    data.pendingShippingInfoDocuments.map((file, i) => {
      var fileBlob = file.file
      console.log(fileBlob)
      return formData.append('fileContents', fileBlob) // name of the file upload box
    })

    function loadDocumentInfoToSI(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      console.log('isFirstAction' + isFirstAction)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        //showSuccessNotification(`${translatedTextsObject.saveDraftSuccess} ${response.freightBookingId}`)
        console.log(response)
        let siDocs = data.shippingInfoDocuments || [] //to store the final booking documents
        if (response && response.length > 0) {
          response.forEach((file, i) => {
            let siDoc = {
              docType: data.pendingShippingInfoDocuments[i].fields[0].value,
              filename: file.fileName,
              description: data.pendingShippingInfoDocuments[i].fields[1].value,
              docId: file.id,
              filesize: file.fileSize
            }

            siDocs.push(siDoc)
          })
        }

        data.shippingInfoDocuments = siDocs

        console.log(data)
        if (isFirstAction) {
          proceedFirstAction(data)
        } else {
          proceedSecondAction(data)
        }
      }
    }

    // createRecord.execute(
    //   ShippingInstructionApiUrls.DOCUMENT_UPLOAD,
    //   formData,
    //   loadDocumentInfoToSI,
    //   onError
    // )
    Api.uploadDocument(createRecord, formData, loadDocumentInfoToSI, onError)
  }

  function onError(error) {
    console.log('connection-request error', error.message)
    setLoading(false)
    showErrorNotification(CommonUtils.getErrorMessage(error))
  }

  function proceedFirstAction(newSIData) {
    console.log('proceedFirstAction', newSIData)
    let url = getUrlForFirstAction()
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
        setLoading(false)
      } else {
        showSuccessNotificationBasedOnCurrentAction(url, response)
        console.log('response', response)
        newSIData = response
        newSIData.siSubmitted = true
        newSIData.siStatus = response.siStatus
        newSIData.updatedDate = response.updatedDate
        newSIData.shippingInfoId = response.shippingInfoId

        setLoading(false)
        //the response data put the booking related info in booking subset, need to expand it in order to show them in submitted page
        navigateToSubmittedPage(SIUtils.expandBookingToShippingInfo(newSIData))
      }
    }

    createRecord.execute(
      url, //ShippingInstructionApiUrls.SUBMIT_SI,
      newSIData,
      onSuccess,
      onError
    )
  }

  function showSuccessNotificationBasedOnCurrentAction(url, response) {
    console.log('showSuccessNotificationBasedOnCurrentAction' + url)
    if (response.siStatus == calistaConstant.SI_STATUS_SI_APPROVED) {
      showSuccessNotification(siTranslatedTextsObject.approveSuccessMsg)
    } else {
      switch (url) {
        case ShippingInstructionApiUrls.SHIPPER_SUBMIT_SI:
          showSuccessNotification(
            `${siTranslatedTextsObject.submitSuccessMsg} ${response.freightBookingId}`
          )
          break

        case ShippingInstructionApiUrls.SHIPPER_UPDATE_SUBMIT_SI:
          showSuccessNotification(
            `${siTranslatedTextsObject.updateSubmitSuccessMsg} ${response.freightBookingId}`
          )
          break

        case ShippingInstructionApiUrls.SHIPPER_UPDATE_CONFIRM_SI:
          showSuccessNotification(
            siTranslatedTextsObject.updateConfirmSuccessMsg
          )
          break

        case ShippingInstructionApiUrls.SHIPPER_CONFIRM_SI:
          showSuccessNotification(siTranslatedTextsObject.confirmSuccessMsg)
          break

        case ShippingInstructionApiUrls.CARRIER_UPDATE_SI:
          showSuccessNotification(siTranslatedTextsObject.updateSuccessMsg)
          break

        case ShippingInstructionApiUrls.CARRIER_CONFIRM_SI:
          showSuccessNotification(siTranslatedTextsObject.confirmSuccessMsg)
          break

        case ShippingInstructionApiUrls.CARRIER_UPDATE_CONFIRM_SI:
          showSuccessNotification(
            siTranslatedTextsObject.updateConfirmSuccessMsg
          )
          break

        case ShippingInstructionApiUrls.CARRIER_SEND_SI:
          showSuccessNotification(siTranslatedTextsObject.sendSuccessMsg)
          break

        default:
          break
      }
    }
  }

  function navigateToSubmittedPage(newSIData) {
    switch (prevMenu) {
      case SIUtils.Menus.newSI:
        history.push({
          pathname: pathMap.CREATE_SI_NEW_SI_SUBMITTED,
          state: { data: newSIData, prevMenu, isSubmitted: true }
        })
        break

      case SIUtils.Menus.mySI:
        history.push({
          pathname: pathMap.MY_SI_MANAGE_SI_SUBMITTED,
          state: { data: newSIData, prevMenu, isSubmitted: true }
        })
        break

      case SIUtils.Menus.draftSI:
        history.push({
          pathname: pathMap.CREATE_SI_DRAFT_SUBMITTED,
          state: { data: newSIData, prevMenu, isSubmitted: true }
        })
        break
      case SIUtils.Menus.manageSI:
        history.push({
          pathname: pathMap.MANAGE_SI_MANAGE_SI_SUBMITTED,
          state: { data: newSIData, prevMenu, isSubmitted: true }
        })
        break

      default:
        break
    }
  }

  function getUrlForFirstAction() {
    /**
     * for carrier
     * 1) status pending carrier confirmation -> confirm
     * 2) status confirmed -> send
     * for shipper
     *  1) draft or new si -> update submit
     *  2) pending re-submission -> submit
     *  3) pending shipper confirmation -> confirm
     */
    let url = ''
    console.log('prevMenu>>' + prevMenu)
    console.log('siStatus>>' + siStatus)
    if (prevMenu === SIUtils.Menus.manageSI) {
      //carrier side, otherwise shipper side
      if (
        siStatus.toString() ===
        calistaConstant.SI_STATUS_PENDING_CARRIER_CONFIRMATION
      ) {
        url = ShippingInstructionApiUrls.CARRIER_CONFIRM_SI
      } else {
        return ShippingInstructionApiUrls.CARRIER_SEND_SI
      }
    } else if (prevMenu === SIUtils.Menus.mySI) {
      if (
        siStatus.toString() ===
        calistaConstant.SI_STATUS_PENDING_SHIPPER_CONFIRMATION
      ) {
        url = ShippingInstructionApiUrls.SHIPPER_CONFIRM_SI
      } else {
        url = ShippingInstructionApiUrls.SHIPPER_SUBMIT_SI
      }
    } else {
      //if status is pending submission, then submit si,otherwise confirm si

      url = ShippingInstructionApiUrls.SHIPPER_UPDATE_SUBMIT_SI
    }

    console.log('first url:' + url)
    return url
  }

  function getUrlForSecondAction() {
    /**
     * for carrier
     * 1) status pending carrier confirmation -> confirm and update
     * 2) status confirmed -> confirm and update
     * for shipper
     *  1) draft or new si -> x
     *  2) pending re-submission and others -> submit and udpate
     *  3) pending shipper confirmation/si confirmed -> confirm  and update
     */
    let url = ''
    console.log('prevMenu>>' + prevMenu)
    console.log('siStatus>>' + siStatus)
    if (prevMenu === SIUtils.Menus.manageSI) {
      url = ShippingInstructionApiUrls.CARRIER_UPDATE_CONFIRM_SI
    } else {
      if (
        siStatus.toString() ===
        calistaConstant.SI_STATUS_PENDING_SHIPPER_CONFIRMATION ||
        siStatus.toString() === calistaConstant.SI_STATUS_SI_CONFIRMED
      ) {
        url = ShippingInstructionApiUrls.SHIPPER_UPDATE_CONFIRM_SI
      } else {
        url = ShippingInstructionApiUrls.SHIPPER_UPDATE_SUBMIT_SI
      }
    }

    console.log('second url:' + url)
    return url
  }

  const onDiscard = () => {
    setConfirmDialogOpen(true)
  }

  const redirectToSIList = () => {
    if (prevMenu === SIUtils.Menus.manageSI) {
      history.push({
        pathname: pathMap.MANAGE_SI
      })
    } else {
      history.push({
        pathname: pathMap.MY_SI
      })
    }
  }

  const navigateToSIListPage = () => {
    switch (prevMenu) {
      case SIUtils.Menus.newSI:
        history.push({
          pathname: pathMap.CREATE_SI,
          state: { shipmentClicked: pathMap === SIUtils.Menus.draftSI }
        })
        break

      case SIUtils.Menus.mySI:
      case SIUtils.Menus.draftSI:
        history.push({
          pathname: pathMap.MY_SI
        })
        break

      case SIUtils.Menus.manageSI:
        history.push({
          pathname: pathMap.MANAGE_SI
        })
        break

      default:
        break
    }
  }

  function setSuccessMsg(updatedData) {
    setSuccessMsgDate(updatedData.updatedDate)
    switch (updatedData.siStatus.toString()) {
      case calistaConstant.SI_STATUS_PENDING_CARRIER_CONFIRMATION:
        setSuccessMsgTitle(siTranslatedTextsObject.siSubmitted)
        setSuccessMsgContent(siTranslatedTextsObject.siSubmittedOn)
        break
      case calistaConstant.SI_STATUS_SI_CONFIRMED:
        setSuccessMsgTitle(siTranslatedTextsObject.siConfirmed)
        setSuccessMsgContent(siTranslatedTextsObject.siConfirmedOn)
        break
      case calistaConstant.SI_STATUS_PENDING_SHIPPER_CONFIRMATION:
        setSuccessMsgTitle(siTranslatedTextsObject.siUpdateConfirmed)
        setSuccessMsgContent(siTranslatedTextsObject.siUpdateConfirmedOn)
        break
      case calistaConstant.SI_STATUS_PENDING_SI_APPROVED:
        setSuccessMsgTitle(siTranslatedTextsObject.siSubmittedForApproval)
        setSuccessMsgContent(siTranslatedTextsObject.siSubmittedOn)
        break
      case calistaConstant.SI_STATUS_SI_APPROVED:
        setSuccessMsgTitle(siTranslatedTextsObject.siApproved)
        setSuccessMsgContent(siTranslatedTextsObject.siApprovedOn)
        break
      default:
        break
    }
  }

  const ReviewSIButtonLayout = () => {
    if (isSubmitted) {
      //step 3
      return (
        <SubmittedSIButtonComponent
          buttonAction={redirectToSIList}
          prevMenu={prevMenu}
        />
      )
    } else {
      if (
        prevMenu === SIUtils.Menus.manageSI
        // ||
        // siStatus.toString() ===
        //   calistaConstant.SI_STATUS_PENDING_SHIPPER_CONFIRMATION
      ) {
        //carrier side
        return (
          <ManageSIReviewSIButtonComponent
            siStatus={siStatus}
            onSaveDraft={onConfirmUpdateSI}
            onEdit={onEdit}
            onDiscard={onDiscard}
            onSubmitSI={onConfirmSI}
            isSkipManagerApproval={isSkipManagerApproval}
          />
        )
      } else {
        //shipper side
        if (
          prevMenu === SIUtils.Menus.newSI ||
          prevMenu === SIUtils.Menus.draftSI
        ) {
          return (
            <DraftSIButtonComponent
              // onSaveDraft={onSaveDraft}
              onEdit={onEdit}
              onDiscard={onDiscard}
              onSubmitSI={onSubmitDraftSI}
            />
          )
        } else {
          return (
            <MySIReviewSIButtonComponent
              siStatus={siStatus}
              onSaveDraft={onConfirmUpdateSI}
              onEdit={onEdit}
              onDiscard={onDiscard}
              onSubmitSI={onSubmitSI}
              isSkipManagerApproval={isSkipManagerApproval}
            />
          )
        }
      }
    }
  }

  useEffect(() => {
    console.log(data)
    SIUtils.calculateTotalWeightVolume(data.shippingInfoDGCargoes)

    setSiStatus(data.siStatus)
    if (data.siSubmitted) {
      // setIsSubmitted(true)
      setBookingStep(2)
      setSuccessMsg(data)
    }
  }, [])

  const newSISteps = [
    siTranslatedTextsObject.stepLabelFillupDetails,
    siTranslatedTextsObject.stepLabelReviewDetails,
    siTranslatedTextsObject.stepLabelSubmit
  ]

  return (
    <Box id="top-level-box">
      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <CngBackdrop loading={loading} />
      </CngGridItem>
      <Paper>
        <Box alignItems='center' pr={15} pl={15}>
          <GeneralStepperComponent
            steps={newSISteps}
            activeStep={bookingStep}
          />
        </Box>

        {/* {'prevmenu' + JSON.stringify(location.state.prevMenu)} */}

        <Box p={5} borderTop={1} borderBottom={1} className='border-light-blue'>
          <Box fontWeight={600} fontSize='h3.fontSize'>
            {/* <Box display={isSubmitted ? 'none' : ''}>
              {translatedTextsObject.reviewBooking}
            </Box> */}
            <Box display={isSubmitted ? '' : 'none'}>
              <ReviewSISuccessMessageComponent
                title={successMsgTitle}
                content={successMsgContent}
                date={successMsgDate}
              />
              {/* <Box display='flex' flexDirection='row'>
                {siTranslatedTextsObject.siSubmitted}
                <Box pl={1.5}>
                  <CheckCircleOutlineOutlinedIcon className='light-green' />
                </Box>
              </Box>
              <Box className='info-label' pt={2} mb={-2}>
                {siTranslatedTextsObject.siSubmittedOn + ' '}
                {CommonUtils.formatDate(
                  moment(),
                  CommonUtils.FORMAT_LONG_DATE_TIME,
                  CommonUtils.DASH
                )}
              </Box> */}
            </Box>
          </Box>
          <Box pt={5}>
            <CngGridItem xs={12} sm={12}>
              <ViewBookingTypeComponent.FormBody bookingTypeData={data} />
            </CngGridItem>
          </Box>
          <Box pt={5}>
            <ViewScheduleComponent.FormBody scheduleData={data} showDetails />
          </Box>
          <Box pt={5}>
            <ViewContactComponent.FormBody contactData={data} />
          </Box>
          <Box pt={5}>
            <ViewShipmentComponent.FormBody shipmentData={data} showDetails />
          </Box>
          <Box pt={5}>
            <ViewSIContainerComponent.FormBody
              containers={containers}
              directSi={directSi}
            />
          </Box>
          {data.hazardous ? (
            <Box pt={5}>
              <ViewSIDgCargoComponent.FormBody cargo={dgCargo} showDetails />
            </Box>
          ) : (
            <Box pt={5}>
              <ViewSICargoComponent.FormBody cargo={cargo} />
            </Box>
          )}
          <Box pt={5}>
            <SIParticularsControlTotalComponent
              cargoList={data.hazardous ? dgCargo : cargo}
              containerList={containers}
              isReview
              isDGCargo={data.hazardous}
            />
          </Box>
          {documents.length > 0 && (
            <Box pt={5}>
              <ViewDocumentComponent.FormBody document={documents} />
            </Box>
          )}

          {uploadedSIDocuments.length > 0 && (
            <Box pt={5}>
              <ViewSIUploadDocumentComponent.FormBody
                uploadedSIDocuments={uploadedSIDocuments}
              />
            </Box>
          )}

          <Box pt={5}>
            <ViewPaymentComponent.FormBody paymentData={data} />
          </Box>
          <QuickScroll />
        </Box>
      </Paper>
      <Paper className='sticky-footer'>
        <Box alignItems='center'>
          <ReviewSIButtonLayout />
        </Box>
      </Paper>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={navigateToSIListPage}
        content={siTranslatedTextsObject.dialogDiscardSIContent}
        okMsg={uiTranslatedTextsObject.ok}
        cancelMsg={uiTranslatedTextsObject.cancel}
        title={siTranslatedTextsObject.dialogDiscardSITitle}
      />
    </Box>
  )
}

const ReviewSIFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
  // toClientDataFormat: toClientDataFormat,
  // toServerDataFormat: toServerDataFormat
})

export default ReviewSIFormProperties
