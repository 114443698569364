import { BasePage as CngBasePage } from 'cng-web-lib'
import CreateSIPage from './CreateSIPage.js'
import CreateSIReviewBooking from './reviewbooking/CreateSIReviewPage'
import CreateSIViewSIDraft from './viewsidraft/CreateSIViewSIDraftPage'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_ShippingInstruction'
import NewSIPage from './newsi/NewSIPage'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import ReviewSIPage from './reviewsi/ReviewSIPage'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'

function WrappedCreateSIPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ShippingInstructionTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <CreateSIPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedCreateSIReviewBookingPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ShippingInstructionTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <CreateSIReviewBooking showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedCreateSIViewSIDraftPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ShippingInstructionTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <CreateSIViewSIDraft showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedCreateSINewPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ShippingInstructionTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <NewSIPage
          showNotification={showSnackbar}
          {...props}
          prevMenu={SIUtils.Menus.newSI}
        />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedCreateSIUpdateSIDraft(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ShippingInstructionTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <NewSIPage
          showNotification={showSnackbar}
          {...props}
          prevMenu={SIUtils.Menus.draftSI}
        />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrapCreateSINewSIReviewPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ShippingInstructionTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <ReviewSIPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedCreateSIPage as CreateSIPage,
  WrappedCreateSIReviewBookingPage as CreateSIReviewBooking,
  WrappedCreateSIViewSIDraftPage as CreateSIViewSIDraft,
  WrappedCreateSINewPage as CreateSINewPage,
  WrappedCreateSIUpdateSIDraft as CreateSIUpdateSIDraft,
  WrapCreateSINewSIReviewPage as CreateSINewSIReviewPage,
  WrapCreateSINewSIReviewPage as CreateSINewSISubmitPage
}
