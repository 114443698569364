import { Box, Paper, Grid, Typography, Snackbar } from '@material-ui/core'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import { useLocation } from 'react-router-dom'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import React, { useEffect, useState } from 'react'
import ViewBookingTypeComponent from 'src/views/shippinginstruction/components/ViewBookingTypeComponent'
import ViewScheduleComponent from 'src/views/shippinginstruction/components/ViewScheduleComponent'
import ViewShipmentComponent from 'src/views/shippinginstruction/components/ViewShipmentComponent'
import ViewContactComponent from 'src/views/shippinginstruction/components/ViewContactComponent'
import ViewPaymentComponent from 'src/views/shippinginstruction/components/ViewPaymentComponent'
import ViewHistoryComponent from 'src/views/shippinginstruction/components/ViewHistoryComponent'
import { useServices, components } from 'cng-web-lib'
import ComponentWrapper from 'src/views/freightbooking/components/ComponentWrapper'
import HeaderBarTop from 'src/views/shippinginstruction/components/HeaderBarTop'
import CommonUtils from 'src/views/common/utils/Utils'
import ViewSIDocumentComponent from 'src/views/shippinginstruction/components/ViewSIDocumentComponent'
import AuditTrailSummary from 'src/views/shippinginstruction/components/AuditTrailSummary'
import AuditTrialRemarks from 'src/components/auditTrail/AuditTrailRemarks'
import * as CalistaConstant from 'src/constants/CalistaConstant'
import Utils from 'src/views/shippinginstruction/shared/Utils'
import Api from 'src/views/freightbooking/shared/api'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import SIActionMenu from 'src/views/shippinginstruction/components/SIActionMenu'
import SICarrierActionMenu from 'src/views/shippinginstruction/components/SICarrierActionMenu'
import ViewAuditDetailDialog from 'src/views/shippinginstruction/components/ViewAuditDetailDialog'
import ViewSIContainerComponent from 'src/views/shippinginstruction/components/ViewSIContainerComponent'
import ViewSICargoComponent from 'src/views/shippinginstruction/components/ViewSICargoComponent'
import ViewSIDgCargoComponent from 'src/views/shippinginstruction/components/ViewSIDgCargoComponent'
import ViewLastModifiedComponent from 'src/views/freightbooking/components/ViewLastModifiedComponent'
import SIParticularsControlTotalComponent from 'src/views/shippinginstruction/components/SIParticularsControlTotalComponent'
import ViewSIUploadDocumentComponent from 'src/views/shippinginstruction/components/ViewSIUploadDocumentComponent'
import QuickScroll from 'src/components/iconbutton/QuickScroll'

function FormFields(props) {
  const { shippingInfo, ports, auditList, userCriteria, countries, blApplicationType, isCarrier } = props
  const translatedTextsObject = FreightBookingTranslationText()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()

  const location = useLocation()

  let role
  if (props.isCarrier) {
    role = location.state.role
  }
  
  console.log('role--> ' + role)
  const [newDate, setNewDate] = useState('')
  const [prevDate, setPrevDate] = useState('')
  const [auditCompare, setAuditCompare] = useState({})
  const [isDialogOpen, setDialogOpen] = useState(false)

  const containers = shippingInfo.shippingInfoContainerBoxes || []
  const cargos = shippingInfo.shippingInfoCargoes
  const hazardous = shippingInfo.hazardous

  const billOfLadingNo = shippingInfo.billOfLadingNo
  const shippingInfoId = shippingInfo.shippingInfoId
  const dgCargos = shippingInfo.shippingInfoDGCargoes || []
  const documents = shippingInfo.shippingInfoDocuments || []
  const uploadedSIDocuments = shippingInfo.shippingInfoUploadDTO || []
  const [lastModifiedDate, setLastModifiedDate] = useState()
  const [lastModifiedText, setLastModifiedText] = useState()
  const [auditSI, setAuditSI] = useState({})
  const { fetchRecords } = useServices()
  //const isEdit = useRef(false)
  console.log('blApplicationType', blApplicationType)
  const [loading, setLoading] = useState(false)
  const { CngGridItem } = components

  const siStatus = props.siStatus

  useEffect(() => {
    switch (siStatus.toString()) {
      case CalistaConstant.SI_STATUS_PENDING_SUBMISSION:
        setLastModifiedText(siTranslatedTextsObject.draftedOn) //draft on
        setLastModifiedDate(shippingInfo.createdDate)
        break
      case CalistaConstant.SI_STATUS_PENDING_SI_APPROVED:
      case CalistaConstant.SI_STATUS_SI_CONFIRMED:
      case CalistaConstant.SI_STATUS_SI_CANCELLED:
      case CalistaConstant.SI_STATUS_PENDING_CARRIER_CONFIRMATION:
      case CalistaConstant.SI_STATUS_PENDING_SHIPPER_CONFIRMATION:
      case CalistaConstant.SI_STATUS_PENDING_SI_RESUBMISSION:
      case CalistaConstant.SI_STATUS_BOOKING_CANCELLED:
        setLastModifiedText(siTranslatedTextsObject.submittedOn) //update on
        setLastModifiedDate(shippingInfo.updatedDate)
        break
      case CalistaConstant.SI_STATUS_SI_APPROVED:
        setLastModifiedText(siTranslatedTextsObject.approvedOn) //update on
        setLastModifiedDate(shippingInfo.approvedDate)
        break
      case CalistaConstant.SI_STATUS_BL_ISSUED:
        setLastModifiedText(siTranslatedTextsObject.issuedOn) //issued on
        setLastModifiedDate(shippingInfo.issueDate)
        break
      case CalistaConstant.SI_STATUS_BL_SURRENDERED:
        setLastModifiedText(siTranslatedTextsObject.surrenderedOn) //surrender on
        setLastModifiedDate(shippingInfo.updatedDate)
        break
      default:
        break
    }
  }, [props])

  useEffect(() => {
    console.log(siStatus)
    if (
      auditList &&
      auditList.length > 1 &&
      siStatus.toString() !== CalistaConstant.SI_STATUS_PENDING_SUBMISSION
    ) {
      let rightVersion = auditList[0].frozenVersion
      let leftVersion = auditList[1].frozenVersion

      const versions = {
        oldVersion: leftVersion,
        newVersion: rightVersion
      }
      Api.fetchAuditTrailCompare(
        fetchRecords,
        versions,
        onAuditTrailLoadSuccess
      )
    }
  }, [auditList])

  function onAuditTrailLoadSuccess(data) {
    console.log('on audit !', data)
    setAuditSI(data)
  }

  return (
    <Box id="top-level-box">
      <Paper>
        <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
          <CngBackdrop loading={loading} />
        </CngGridItem>
        <HeaderBarTop shippingInfo={shippingInfo} isCarrier={isCarrier} />
        <Box p={5} borderTop={1} borderBottom={1} className='border-light-blue'>

          <Grid
            container
            xs={12}
            sm={12}
            alignItems='center'
            style={{ marginBottom: 40 }}
          >
            <Grid container xs={12}>
              {siStatus.toString() !==
                CalistaConstant.SI_STATUS_PENDING_SUBMISSION &&
                auditList.length > 1 && (
                  <Grid item xs={8}>
                    <AuditTrialRemarks />
                  </Grid>
                )}
            </Grid>
          </Grid>
          {siStatus.toString() !==
            CalistaConstant.SI_STATUS_PENDING_SUBMISSION &&
            auditList.length > 1 && (
              <Box pb={5}>
                <AuditTrailSummary
                  shipInfo={auditSI}
                  auditList={auditList}
                  ports={ports}
                  countries={countries}
                  status={Utils.SIStatus}
                  blApplicationType={blApplicationType}
                />
              </Box>
            )}

          <Box pb={5}>
            <Grid
              container
              xs={12}
              sm={12}
              alignItems='center'
              style={{ marginBottom: 40 }}
            >
              <ViewLastModifiedComponent
                text={lastModifiedText}
                date={lastModifiedDate}
              />
              <Grid
                item
                xs
                direction='row'
                justify='flex-end'
                alignItems='center'
              ></Grid>
              <Box>
                <img
                  src={`${process.env.PUBLIC_URL}/static/images/Logo_CALISTA.svg`}
                ></img>
              </Box>
              <Box>&nbsp;{siTranslatedTextsObject.bookingId}</Box>
              <Box className={'boldLabel'}>
                &nbsp;
                {shippingInfo.booking == null
                  ? ''
                  : shippingInfo.booking.freightBookingId}
              </Box>
            </Grid>
          </Box>

          <Grid container xs={12} sm={12} justify='space-between' spacing={2}>
            <Grid item xs={6}>
              {shippingInfo.shipmentType && (
                <Box>
                  <ViewBookingTypeComponent.FormBody
                    bookingTypeData={shippingInfo}
                  />
                </Box>
              )}
            </Grid>
            {shippingInfo.carrierRemarks && (
              <Grid item xs={6}>
                <ComponentWrapper
                  header={translatedTextsObject.carrierComments}
                >
                  <Box
                    px={2}
                    py={3}
                    style={CommonUtils.auditHighLight(auditSI, ['carRemarks'])}
                  >
                    <Typography>{shippingInfo.carrierRemarks}</Typography>
                  </Box>
                </ComponentWrapper>
              </Grid>
            )}
          </Grid>
          {shippingInfo.booking == null ? (
            <Box></Box>
          ) : (
            <Box pt={5}>
              <ViewScheduleComponent.FormBody
                scheduleData={shippingInfo}
                showDetails
                auditSI={auditSI}
                isCarrier
              />
            </Box>
          )}

          {shippingInfo.booking == null ? (
            <Box></Box>
          ) : (
            <Box pt={5}>
              <ViewContactComponent.FormBody
                contactData={shippingInfo}
                auditSI={auditSI}
              />
            </Box>
          )}

          {shippingInfo.moveType == null ? (
            <Box></Box>
          ) : (
            <Box pt={5}>
              <ViewShipmentComponent.FormBody
                shipmentData={shippingInfo}
                showDetails
                auditSI={auditSI}
                isCarrier
              />
            </Box>
          )}

          {containers !== undefined && containers.length > 0 && (
            <Box pt={5}>
              <ViewSIContainerComponent.FormBody
                containers={containers}
                auditSI={auditSI}
                directSi={shippingInfo.directSi}
              />
            </Box>
          )}

          {!hazardous && cargos !== undefined && cargos.length > 0 && (
            <Box pt={5}>
              <ViewSICargoComponent.FormBody cargo={cargos} auditSI={auditSI} />
            </Box>
          )}

          {hazardous && dgCargos !== undefined && dgCargos.length > 0 && (
            <Box pt={5}>
              <ViewSIDgCargoComponent.FormBody
                cargo={dgCargos}
                showDetails
                auditSI={auditSI}
              />
            </Box>
          )}

          {cargos !== undefined && containers !== undefined && (
            <Box pt={5}>
              {/* <ViewSIControlTotalComponent.FormBody
                containerList={containers}
                cargoList={hazardous ? dgCargos : cargos}
                isDGCargo={hazardous}
              /> */}
              <SIParticularsControlTotalComponent
                cargoList={hazardous ? dgCargos : cargos}
                containerList={containers}
                isReview
                isDGCargo={hazardous}
              />
            </Box>
          )}

          {documents.length > 0 && (
            <Box pt={5}>
              <ViewSIDocumentComponent.FormBody
                document={documents}
                auditSI={auditSI}
                allowDownload
                module={CalistaConstant.MODULE_SI}
                siStatus={siStatus}
                shippingInfoId={shippingInfoId}
                billOfLadingNo={billOfLadingNo}
                setLoading={setLoading}
                showNotification={props.showNotification}
              />
            </Box>
          )}

          {uploadedSIDocuments.length > 0 && (
            <Box pt={5}>
              <ViewSIUploadDocumentComponent.FormBody
                uploadedSIDocuments={uploadedSIDocuments}
              />
            </Box>
          )}

          <Box pt={5}>
            <ViewPaymentComponent.FormBody
              paymentData={shippingInfo}
              auditSI={auditSI}
            />
          </Box>
          <Box pt={5}>
            <ViewHistoryComponent.FormBody
              //isSIdraft={isSIdraft}
              auditList={auditList}
              userCriteria={userCriteria}
              setDialogOpen={(data) => setDialogOpen(data)}
              setAuditCompare={(data) => setAuditCompare(data)}
              setNewDate={(data) => setNewDate(data)}
              setPrevDate={(data) => setPrevDate(data)}
            // tempComparedata={auditSI} //remember to remove after testing
            />

            <ViewAuditDetailDialog
              isDialogOpen={isDialogOpen}
              auditCompare={auditCompare}
              newDate={newDate}
              prevDate={prevDate}
              currentSI={shippingInfo}
              currentSIStatus={shippingInfo.siStatus}
              closeDialog={() => setDialogOpen(false)}
              ports={ports}
            />
          </Box>

          <Box alignItems='center'>
            {props.isCarrier ? (
              <SICarrierActionMenu
                si={shippingInfo}
                page={Utils.Page.SIDetail}
                {...props}
                role={role}
              />
            ) : (
              <SIActionMenu
                si={shippingInfo}
                page={Utils.Page.SIDetail}
                {...props}
              />
            )}
          </Box>
          <QuickScroll />
        </Box>
      </Paper>
    </Box>
  )
}

const ViewSIPageFormProperties = Object.freeze({
  // formikProps: FORMIK_PROPS,
  FormFields: FormFields
  // toClientDataFormat: toClientDataFormat,
  // toServerDataFormat: toServerDataFormat
})

export default ViewSIPageFormProperties
