import ContactValidationSchema from 'src/views/shippinginstruction/shared/validation/ContactValidationSchema'
import ScheduleValidationSchema from 'src/views/shippinginstruction/shared/validation/ScheduleValidationSchema'
import ShipmentValidationSchema from 'src/views/shippinginstruction/shared/validation/ShipmentValidationSchema'
import ContainerValidationSchema from 'src/views/shippinginstruction/shared/validation/ContainerValidationSchema'
import CargoValidationSchema from 'src/views/shippinginstruction/shared/validation/CargoValidationSchema'
import PaymentValidationSchema from 'src/views/shippinginstruction/shared/validation/PaymentValidationSchema'
import DocumentValidationSchema from 'src/views/shippinginstruction/shared/validation/DocumentValidationSchema'
import BookingTypeValidationSchema from 'src/views/shippinginstruction/shared/validation/BookingTypeValidationSchema'

function useManageSIMakeValidationSchema() {
  return ContainerValidationSchema()
    .concat(CargoValidationSchema())
    .concat(PaymentValidationSchema())
    .concat(ContactValidationSchema())
    .concat(ScheduleValidationSchema())
    .concat(ShipmentValidationSchema())
    .concat(DocumentValidationSchema())
    .concat(BookingTypeValidationSchema())
  // null
}

export default useManageSIMakeValidationSchema
