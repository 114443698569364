import * as CalistaConstant from 'src/constants/CalistaConstant'

import { Box} from '@material-ui/core'
import { Card, CardContent, Fab, Grid } from '@material-ui/core'
import {  Yup, components, useServices } from 'cng-web-lib'
import React, { Fragment, useEffect, useRef, useState } from 'react'

import Api from 'src/views/freightbooking/shared/api'
import ApiParser from 'src/views/freightbooking/shared/apiParser'
import ButtonTabComponent from '../components/ButtonTabComponent'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import FreeTextSearchComponent from 'src/views/shippinginstruction/components/FreeTextSearchComponent'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import SIApi from 'src/views/shippinginstruction/shared/api'
import SIFilterPanelComponent from '../components/SIFilterPanelComponent'
import SIListComponent from '../components/SIListComponent'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import ScrollTop from 'src/views/freightbooking/components/ScrollTop'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import SiApiParser from 'src/views/shippinginstruction/shared/apiParser'
import Utils from 'src/views/common/utils/Utils'
import { useLocation } from 'react-router-dom'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  dateRange: 'all',
  pendingClicked: true,
  filterText: '',
  sortCode: '',
  pendingStatusCount: 0,
  processedStatusCount: 0,
  statusCodes: [
    CalistaConstant.SI_STATUS_PENDING_CARRIER_CONFIRMATION,
    CalistaConstant.SI_STATUS_SI_APPROVED,
    CalistaConstant.SI_STATUS_PENDING_SHIPPER_CONFIRMATION,
    CalistaConstant.SI_STATUS_SI_CONFIRMED,
    CalistaConstant.SI_STATUS_PENDING_SI_APPROVED,
    CalistaConstant.SI_STATUS_PENDING_SI_RESUBMISSION,
    CalistaConstant.SI_STATUS_SI_CANCELLED,
    CalistaConstant.SI_STATUS_BL_ISSUED,
    CalistaConstant.SI_STATUS_BL_SURRENDERED,
    CalistaConstant.SI_STATUS_BOOKING_CANCELLED
  ],
  status: {},
  bls: {},
  carriers: {},
  showArchived: true,
  showDirectSI: false,
  myTask: true,
  statusTabChanged: true,
  blAppTypeCodes: ['SI_SB', 'SI_BL']
})

function DEFAULT_VALIDATION_SCHEMA(translate) {
  return Yup.object({})
}

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: DEFAULT_VALIDATION_SCHEMA
  //makeValidationSchema: makeValidationSchema
}

function FormFields({ disabled, showNotification, shouldHideMap }) {
  //move this from BookingTypeComponent to share with Cargo component
  const { fetchRecords } = useServices()
  const location = useLocation()
  const [fromBookingPage, setFromBookingPage] = useState(
    location.state && location.state.fromMyBooking
  )
  const [searchBookingID, setSearchBookingID] = useState(
    !fromBookingPage
      ? ''
      : location.state.booking.dockey
      ? location.state.booking.dockey
      : location.state.booking.freightBookingId
  )

  const [statusCodesField, , { setValue: setStatusCodesField }] =
    useField('statusCodes')
  const [blAppTypeCodesField, , { setValue: setBlAppTypeCodesField }] =
    useField('blAppTypeCodes')
  /*   const [, , { setValue: setDateRangeField }] = useField('dateRange') */
  const [statusField, , { setValue: setStatusField }] = useField('status')
  const [sortCodeField, , { setValue: setSortCodeField }] = useField('sortCode')
  const [, , { setValue: setSortByListField }] = useField('sortByList')

  const [filterTextField, , { setValue: setFilterTextField }] =
    useField('filterText')

  const [carriersField, , { setValue: setCarriersField }] = useField('carriers')
  const [shippersField, , { setValue: setShippersField }] = useField('shippers')
  const [showArchivedField, , { setValue: setShowArchivedField }] =
    useField('showArchived')
  const [showDirectSIField, , { setValue: setShowDirectSIField }] =
    useField('showDirectSI')
  const [myTaskField, , { setValue: setMyTaskField }] = useField('myTask')
  const [partyIdsField, , { setValue: setPartyIdsField }] = useField('partyIds')
  const [statusTabChangedField, , { setValue: setStatusTabChanged }] =
    useField('statusTabChanged')
  const [reloadStatus, setReloadStatus] = useState(false)
  const [blsField, , { setValue: setBlsField }] = useField('bls')
  const [dateRangeField, , { setValue: setDateRangeField }] =
    useField('dateRange')

  const defaultDateRange = 'all' //default value to query via API
  const VALUE_ALL = 'ALL'
  const defaultShowArchived = true
  const defaultShowDirectSI = false

  const [dateRange, setDateRange] = useState()
  const [sortByList, setSortByList] = useState()
  const [filterText, setFilterText] = useState()
  const [siList, setSiList] = useState([])
  const [showArchived, setShowArchived] = useState()
  const [showDirectSI, setShowDirectSI] = useState()
  const [showMyTask, setShowMyTask] = useState()
  const [loading, setLoading] = useState(true)
  const [shownCount, setShownCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const translatedTextsObject = ShippingInstructionTranslationText()
  const clerkStatusCode = [
    CalistaConstant.SI_STATUS_PENDING_CARRIER_CONFIRMATION,
    CalistaConstant.SI_STATUS_SI_APPROVED,
    CalistaConstant.SI_STATUS_PENDING_SHIPPER_CONFIRMATION,
    CalistaConstant.SI_STATUS_SI_CONFIRMED,
    CalistaConstant.SI_STATUS_PENDING_SI_APPROVED,
    CalistaConstant.SI_STATUS_PENDING_SI_RESUBMISSION,
    CalistaConstant.SI_STATUS_SI_CANCELLED,
    CalistaConstant.SI_STATUS_BL_ISSUED,
    CalistaConstant.SI_STATUS_BL_SURRENDERED,
    CalistaConstant.SI_STATUS_BOOKING_CANCELLED
  ]

  const managerStatusCode = [CalistaConstant.SI_STATUS_PENDING_SI_APPROVED]

  const manualRefresh = () => {
    setRefresh(!refresh)
    setLoading(true)
    setSiList([])
    setShownCount(0)
  }

  const handleSortByChange = (e) => {
    console.log('handleSortByChange')
    //alert(e.target.value)
    if (e.target.value != null && e.target.value != 0) {
      setSortCodeField(e.target.value)
      setStatusTabChanged(true)
      manualRefresh()
      // submitForm()
    }
  }

  const handelFreeTextSearch = () => {
    setStatusTabChanged(true)
    manualRefresh()
  }

  const handleClearFiltreAndTextButtonClick = () => {
    console.log('handleClearFiltreAndTextButtonClick')

    setFilterText('')
    setFilterTextField('')
    handleClearAllButtonClick()
  }
  const handleClearAllButtonClick = () => {
    console.log('handleClearAllButtonClick')

    setDateRange(defaultDateRange)
    setDateRangeField(defaultDateRange)
    let newStatus = [...status]

    
    let newStatusCode = getNewStatuCodeForClearAll(newStatus)

   
    newStatus = newStatus.map((item) => ({
      ...item,
      checked: item.eligible
    }))

    console.log(newStatus)
    //setStatus(newStatus)
    setReloadStatus(true)
    setStatusField(newStatus)

    let newCarriers = [...carriers]
    newCarriers = newCarriers.map((item) => ({
      ...item,
      checked: true
    }))

    let newShippers = [...shippers]
    newShippers.forEach((item) => {
      item.checked = true
    })

    setCarriers(newCarriers)
    setCarriersField(newCarriers)

    setShippers(newShippers)
    setShippersField(newShippers)

    setBlsField([])
    setBlAppTypeCodesField(DEFAULT_INITIAL_VALUES.blAppTypeCodes)

    setShowArchivedField(defaultShowArchived)
    setShowArchived(defaultShowArchived)

    setShowDirectSIField(defaultShowDirectSI)
    setShowDirectSI(defaultShowDirectSI)

    setShowMyTask(false)
    setMyTaskField(false)


    setStatusCodesField(newStatusCode)

    setStatusTabChanged(true) //force carrier list load from api
    manualRefresh()
    //submitForm()
  }

  const dateRangeChange = (e) => {
    console.log(e.target.defaultValue)
    setStatusTabChanged(true)
    setDateRange(e.target.defaultValue)
    setDateRangeField(e.target.defaultValue)

    manualRefresh()
  }

  const handleFilterTextChange = (e) => {
    console.log(e.target)
    setFilterText(e.target.value)
    setFilterTextField(e.target.value)

    // submitForm()
  }

  const onStatusCheckBoxChange =
    (index) =>
    ({ target: { checked } }) => {
      setReloadStatus(false)
      console.log('handleStatusCheckBoxChange' + index)
      let newStatus = [...status]
      const currentStatus = status[index]
      if (currentStatus.statusDesc !== VALUE_ALL) {
        const allStatus = status[status.length - 1]

        newStatus[index] = { ...currentStatus, checked: !currentStatus.checked }
        newStatus[status.length - 1] = { ...allStatus, checked: false }
      } else {
        newStatus = newStatus.map((item) => ({
          ...item,
          checked: item.eligible ? !currentStatus.checked : false
        }))
      }

      setStatus(newStatus)
      setStatusField(newStatus)
      console.log(newStatus)    

      //get new status code and list
      let newStatusCode = getNewStatuCode(newStatus)
      console.log(newStatusCode)

      setStatusCodesField(newStatusCode)
      setStatusTabChanged(true) //force carrier list load from api
      manualRefresh()
      //submitForm()
    }

  function getNewStatuCode(newStatus) {
    if(isCarrierManager()&& myTaskField.value){
      let pendingApprovalStatus = newStatus.filter((v)=> v.statusCode===5211)
      if(pendingApprovalStatus[0].checked){
       
        return [5211]
      }else{
      
        return []
      }
    }
    else{
      let temp = []
      newStatus.filter((v) => v.checked).map((v) => temp.push(v.statusCode))

      return temp
    }
  }

  function getNewStatuCodeForClearAll(newStatus) {
   
    //in the case of clear all, need to get all eligible status code as search criteria
    let temp = []
    newStatus.filter((v) => (v.eligible|| v.checked)).map((v) => temp.push(v.statusCode))
    
    return temp
  }

  const showArchivedChange = (e) => {
    console.log('showArchivedChange')
    console.log(e.target.checked)
    setShowArchivedField(e.target.checked)
    setStatusTabChanged(true) //force carrier list load from api
    manualRefresh()
    //submitForm()
  }

  const showDirectSIChange = (e) => {
    console.log('showDirectSIChange')
    console.log(e.target.checked)
    setShowDirectSIField(e.target.checked)
    setStatusTabChanged(true)
    manualRefresh()
  }

  const onMyTaskChange = (e) => {
    console.log('onMyTaskChange' + e.target.checked)

    setMyTaskField(e.target.checked)
    setReloadStatus(true)
    setShowMyTask(e.target.checked)
    if (e.target.checked) {
      setStatusCodesField(managerStatusCode)
      searchCriteria.statusCodes = managerStatusCode
    } else {
      setStatusCodesField(clerkStatusCode)
      searchCriteria.statusCodes = clerkStatusCode
    }
    setStatusTabChanged(true)
    manualRefresh()
  }

  const onBLCheckBoxChange =
    (index) =>
    ({ target: { checked } }) => {
      console.log('onBLCheckBoxChange ' + index)
      let newBls = [...bls]
      const bl = bls[index]
      //console.log(bl.partyId)
      if (bl.statusDesc !== VALUE_ALL) {
        const allBl = bls[bls.length - 1]

        newBls[index] = { ...bl, checked: !bl.checked }
        newBls[bls.length - 1] = { ...allBl, checked: false }
      } else {
        newBls = newBls.map((item) => ({
          ...item,
          checked: !bl.checked
        }))
      }

      setBls(newBls)
      setBlsField(newBls)
      console.log(newBls)

      let newBlList = getNewBls(newBls)
      console.log(newBlList)
      setBlAppTypeCodesField(newBlList)
      setStatusTabChanged(true)

      manualRefresh()
    }

  function getNewBls(newBls) {
    let temp = []
    newBls.filter((v) => v.checked == true).map((v) => temp.push(v.statusCode))

    return temp
  }

  //on function
  const onCarrierCheckBoxChange =
    (index) =>
    ({ target: { checked } }) => {
      console.log('onCarrierCheckBoxChange ' + index)
      let newCarriers = [...carriers]
      const carrier = carriers[index]
      console.log(carrier.partyId)
      if (carrier.nameOth !== VALUE_ALL) {
        const allCarrier = carriers[carriers.length - 1]

        newCarriers[index] = { ...carrier, checked: !carrier.checked }
        newCarriers[carriers.length - 1] = { ...allCarrier, checked: false }
      } else {
        newCarriers = newCarriers.map((item) => ({
          ...item,
          checked: !carrier.checked
        }))
      }

      setCarriers(newCarriers)
      setCarriersField(newCarriers)

      let temp = []
      newCarriers
        .filter((v) => v.checked == true)
        .map((v) => temp.push(v.partyId))

      console.log(temp)

      setPartyIdsField(temp)
      setStatusTabChanged(false)

      manualRefresh()
      // submitForm()
    }

  const onShipperCheckBoxChange =
    (index) =>
    ({ target: { checked } }) => {
      console.log('onShipperCheckBoxChange ' + index)
      let newShippers = [...shippers]
      const shipper = shippers[index]
      console.log('check shipper data', shipper)
      console.log(shipper.carrierPartyId)
      if (shipper.nameOth !== VALUE_ALL) {
        const allShipper = shippers[shippers.length - 1]

        newShippers[index] = { ...shipper, checked: !shipper.checked }
        newShippers[shippers.length - 1] = { ...allShipper, checked: false }
      } else {
        newShippers = newShippers.map((item) => ({
          ...item,
          checked: !shipper.checked
        }))
      }

      setShippers(newShippers)
      setShippersField(newShippers)

      let temp = []
      newShippers
        .filter((v) => v.checked === true)
        .forEach((v) => temp.push(v.carrierPartyId))

      console.log(temp)

      setPartyIdsField(temp)
      setStatusTabChanged(false)

      manualRefresh()
      // submitForm()
    }

  let searchCriteria = {
    filterText: filterTextField.value,
    dateRange: dateRangeField.value,
    blAppTypeCodes: blAppTypeCodesField.value,
    statusCodes: statusCodesField.value,
    showArchived: showArchivedField.value,
    carrierCodes: partyIdsField.value,
    sortCode: Utils.isEmptyString(sortCodeField.value)
      ? 1
      : sortCodeField.value,
    showDirectSIOnly: showDirectSIField.value
  }

  const [carriers, setCarriers] = useState([])
  const [shippers, setShippers] = useState([])

  const [status, setStatus] = useState([])
  const [bls, setBls] = useState([])

  const [loadingCriteria, setLoadingCriteria] = useState()
  const [pageError, setPageError] = useState(false)
  const [refresh, setRefresh] = useState(false)
  // const [roleField, , { setValue: setRoleField }] = useField('role')

  const [role, setRole] = useState()
  useEffect(() => {
    setPageError(false)
    setSortCodeField(
      sortCodeField.value == null || sortCodeField.value == ''
        ? 1
        : sortCodeField.value
    )

    if (sortByList == null) {
      //fetch sort code when the page is first loaded, the result will remain static
      getSortingType()
    }

    if (role == null) {
      fetchUserRoleFromAPI()
    } else {
      //get status code and count
      fetchStatusCodeAndCountFromAPI()
    }

    //get party id based on status code, date range and sort code
  }, [refresh])

  function fetchUserRoleFromAPI() {
    fetchRecords.execute(
      `${process.env.REACT_APP_USER_ORIGIN_URL}/tpr-auth/user-detail/by-login/get`,
      undefined,
      (data) => {
        console.log('fetchUserRoleFromAPI data: ', data)
        if (data.length > 0) {
          let userProfileId = data[0].userProfileId
          fetchUserRoleByProfileId(userProfileId)
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function fetchUserRoleByProfileId(userProfileId) {
    console.log('fetchUserRoleByProfileId>' + userProfileId)
    let userProfileIdMap = { userId: userProfileId }
    fetchRecords.execute(
      `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user-role/get-by-userprofile/get`,
      { customData: userProfileIdMap },
      (data) => {
        console.log('fetchUserRoleByProfileId data: ', data)
        if (data.content.length > 0) {
          let roleIds = []
          data.content.forEach((role) => {
            roleIds.push(role.roleId)
          })
          console.log('fetchUserRoleByProfileId roldid: ' + roleIds)
          //fetch role id details
          fetchUserRoleByRoleId(roleIds)
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function fetchUserRoleByRoleId(roleIds) {
    fetchRecords.execute(
      `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/role/get`,
      {
        customData: {},
        filters: [
          {
            field: 'id',
            operator: 'IN',
            value: roleIds
          }
        ]
      },
      (data) => {
        console.log('fetchUserRoleByRoleId', data)
        if (data.content && data.content.length > 0) {
          let roleCodes = []
          data.content.forEach((roleCode) => {
            roleCodes.push(roleCode.roleCode.toUpperCase())
          })

          console.log('fetchUserRoleByRoleId role code: ' + roleCodes)

          let carrierRole = SIUtils.checkCarrierRole(roleCodes)
          console.log('fetchUserRoleByRoleId-> ' + carrierRole)
          setRole(carrierRole)
          let initialCode =
            carrierRole !== 'CLERK' ? managerStatusCode : clerkStatusCode
          searchCriteria.statusCodes = initialCode

          // setShowMyTask(carrierRole !== 'CLERK')
          setStatusCodesField(initialCode)
          fetchStatusCodeAndCountFromAPI()
        }
      },
      (error) => {
        console.log('fetchUserRoleByRoleId : ' + error)
      }
    )
  }

  function isCarrierManager() {
    return Utils.isNotNull(role) && role !== SIUtils.carrierRole.CLERK
  }

  function populatePartyId(dat) {
    console.log(dat)
    let FromApi = SiApiParser.parsePartyId(dat)
    console.log('FromApi', FromApi)
    if (FromApi[0] !== undefined) {
      FromApi.map((elem) => (elem.bookingCount = elem.shippingCount))
      let newRecord = { ...FromApi[0] }
      newRecord.nameOth = 'ALL'
      newRecord.bookingCount = FromApi.reduce((a, b) => a + b.shippingCount, 0)
      FromApi = [...FromApi, newRecord]

      console.log(FromApi)

      let partyIdsForSearch = getPartyIdForSIList(dat)
      console.log('partyIdsForSearch', partyIdsForSearch)
      console.log(partyIdsField)
      console.log('searchCriteria', searchCriteria)

      getShippingInfoList()
    } else {
      setLoading(false)
      setPartyIdsField(
        Utils.isEmptyString(partyIdsField.value) ? [] : partyIdsField.value
      )
    }

    if (reloadCarrierCheckBoxNeeded()) {
      setCarriers(FromApi)
      setCarriersField(FromApi)

      setShippers(FromApi)
      setShippersField(FromApi)
    } else {
      setCarriers(carriersField.value)
      setShippers(shippersField.value)
      //  setCarriersField(location.state.carriers)
    }
  }
  function fetchPartyIdFromAPI() {
    SIApi.fetchPartyId(fetchRecords, searchCriteria, populatePartyId, onError)
    //Api.fetchPartyId(fetchRecords, searchCriteria, populatePartyId, onError)
  }

  function reloadCarrierCheckBoxNeeded() {
    return statusTabChangedField.value
  }

  function getPartyIdForSIList(carrierList) {
    console.log('getPartyIdForBookingList')
    let partyIdss = []
    if (reloadCarrierCheckBoxNeeded()) {
      console.log('loading party id from api')

      carrierList.map((v) => {
        console.log(v.carrierPartyId)
        partyIdss.push(v.carrierPartyId)
      })

      //temp for booking list
      searchCriteria.carrierCodes = partyIdss
      // searchCriteria.statusCodes = [5107]
      setPartyIdsField(partyIdss)
    } else {
      console.log('loading party id from hisotry')
      setPartyIdsField(partyIdsField.value)
    }
    return partyIdss
  }

  function getDisableClerkStatus() {
    let result = false

    console.log('getDisableClerkStatus', showMyTask === undefined)
    console.log(
      'getDisableClerkStatus',
      !Utils.isEmpty(searchCriteria.statusCodes)
    )
    console.log(
      'getDisableClerkStatus',
      searchCriteria.statusCodes[0] === '5211'
    )
    console.log('getDisableClerkStatus', role !== SIUtils.carrierRole.CLERK)
    if (
      showMyTask === undefined &&
      !Utils.isEmpty(searchCriteria.statusCodes) &&
      searchCriteria.statusCodes[0] === '5211' &&
      role !== SIUtils.carrierRole.CLERK
    ) {
      //user did not touch show task switch toggle yet
      result = true
    } else if (showMyTask) {
      result = true
    }

    console.log('getDisableClerkStatus', result)

    return result
  }

  function populateStatusCodeAndCount(dat) {
    //console.log('showMyTask' + showMyTask)
    let disableClerkStatus = getDisableClerkStatus()
    //console.log('disableClerkStatus' + disableClerkStatus)
    let isHelpdesk = false
    const data = SiApiParser.parseStatusCodeAndCount(
      dat,
      disableClerkStatus,
      isHelpdesk
    )
    console.log('parseStatusCodeAndCount', data)

    setTotalCount(data.totalCount)
    const SiStatusList = data.siStatusList

    setStatus(getStatusCheckBoxGroup(SiStatusList))
    // setStatusCodesField(
    //   location.state == null ? pendingStatusCode : location.state.statusCodes
    // )
    /* setStatusField(
      location.state == null ? data.pendingList : location.state.status
    ) */
    fetchBLApplicationTypeFromAPI()
  }

  function getStatusCheckBoxGroup(SiStatusList) {
    let statusCheckBoxGroup = []
    if (Utils.isEmptyObject(statusField.value) || reloadStatus) {
      console.log('this is the first time status load')
      statusCheckBoxGroup = SiStatusList
    } else {
      //update the status count from api
      console.log('update the status count from api')
      statusField.value.forEach((siStatus) => {
        let target = SiStatusList.filter(
          (v) => v.statusCode === siStatus.statusCode
        )
        console.log('target', target)
        siStatus.statusDesc = target[0].statusDesc
      })

      statusCheckBoxGroup = statusField.value
      statusCheckBoxGroup[statusField.value.length-1].statusDesc = VALUE_ALL
      //newStatus.filter((v) => v.checked).map((v) => temp.push(v.statusCode))
    }
    // return   Utils.isEmptyObject(statusField.value) || reloadStatus
    // ? SiStatusList
    // : statusField.value
    console.log('statusCheckBoxGroup', statusCheckBoxGroup)
    return statusCheckBoxGroup
  }

  function fetchBLApplicationTypeFromAPI() {
    SIApi.fetchBLApplicationType(
      fetchRecords,
      searchCriteria,
      populateBLApplicationType,
      onBLApplicationTypeError
    )
  }

  function onBLApplicationTypeError(error) {
    console.log(error)
    fetchPartyIdFromAPI()
  }

  function populateBLApplicationType(dat) {
    console.log(dat)
    let BLList = SiApiParser.parseBlApplicationType(dat)
    console.log(BLList)
    setBls(Utils.isEmptyObject(blsField.value) ? BLList : blsField.value)

    fetchPartyIdFromAPI()
  }

  function fetchStatusCodeAndCountFromAPI() {
    SIApi.fetchStatusCodeAndCount(
      fetchRecords,
      populateStatusCodeAndCount,
      onStatusError
    )
  }

  function onStatusError(error) {
    console.log(error)
    fetchBLApplicationTypeFromAPI()
  }

  function populateSortingType(dat) {
    console.log(dat)
    const list = ApiParser.parseSortingType(dat)
    console.log(list)
    setSortByList(list)
    setSortByListField(list)
  }

  function getLeftCount() {
    return totalCount - shownCount
  }

  function getSortingType() {
    Api.fetchSortingType(fetchRecords, populateSortingType, onError)
  }

  function onError(error) {
    setPageError(true)
    setLoading(false)
  }

  function populateShippingInfoList(data) {
    //test
    // let temp = data.content
    // //let tempStatusList = [5101, 5102, 5103, 5104, 5105, 5107, 5101, 5102, 5103]
    // let tempStatusList = [5202, 5209, 5212, 5211, 5210, 5203, 5301, 5302, 5213]
    // let tempStatusDesc = [
    //   'Pending Carrier Confirmation',
    //   'Pending Shipper Confirmation',
    //   'Pending SI Re-submission',
    //   'Pending SI Approved',
    //   'SI Confirmed',
    //   'SI Approved',
    //   'B/L Issued',
    //   'B/L Surrendered',
    //   'SI Cancelled'
    // ]
    // let i = 0
    // temp.map((m) => {
    //   m.siStatus = tempStatusList[i]
    //   m.siStatusDesc = tempStatusDesc[i]
    //   m.closed = i % 2 == 0 ? true : false
    //   i = i >= 8 ? 0 : i + 1
    // })
    // console.log(temp)
    // setSiList(temp)

    //end of test
    setSiList(data.content) //please uncommend after test
    setLoading(false)
    setShownCount(data.totalElements)
  }
  function getShippingInfoList() {
    SIApi.fetchShippingInfoList(
      fetchRecords,
      searchCriteria,
      populateShippingInfoList,
      onError
    )
    setLoadingCriteria(searchCriteria)
  }

  return (
    <Fragment>
      <Grid container xs={12} justify='center' spacing={1}>
        <CngGridItem xs={12} sm={9}>
          <Card>
            <CardContent>
              <CngGridItem xs={12}>
                <ButtonTabComponent.FormBody
                  listCount={shownCount}
                  shownCount={totalCount}
                  sortByList={sortByList}
                  handleSortByChange={handleSortByChange}
                />
              </CngGridItem>

              <CngGridItem xs={12}>
                <Box class='summary-list-items--container'>
                  <SIListComponent.FormBody
                    formLoading={loading}
                    shownCount={shownCount}
                    searchCriteria={loadingCriteria}
                    siList={siList}
                    showNotification={showNotification}
                    handleClearAllButtonClick={
                      handleClearFiltreAndTextButtonClick
                    }
                    leftCount={getLeftCount()}
                    isCarrier
                    refreshAction={manualRefresh}
                    setPageError={setPageError}
                    role={role}
                  />
                </Box>
              </CngGridItem>
            </CardContent>
          </Card>
        </CngGridItem>

        <CngGridItem xs={12} sm={3}>
          <Grid container xs={12} spacing={1}>
            <CngGridItem xs={12}>
              <FreeTextSearchComponent.FormBody
                handleInputChange={handleFilterTextChange}
                filterText={filterText}
                handelFreeTextSearch={handelFreeTextSearch}
              />
            </CngGridItem>
            <CngGridItem xs={12}>
              <SIFilterPanelComponent
                handleClearAllButtonClick={handleClearAllButtonClick}
                onStatusCheckBoxChange={onStatusCheckBoxChange}
                showArchivedChange={showArchivedChange}
                onCarrierCheckBoxChange={onCarrierCheckBoxChange}
                onShipperCheckBoxChange={onShipperCheckBoxChange}
                onBLCheckBoxChange={onBLCheckBoxChange}
                showDirectSIChange={showDirectSIChange}
                dateRange={dateRangeField.value}
                dateRangeChange={dateRangeChange}
                carriers={carriers}
                shippers={shippers}
                bls={bls}
                status={status}
                showArchived={showArchived}
                showDirectSI={showDirectSI}
                draft={true}
                showStatus
                isCarrier
                isCarrierManager={isCarrierManager()}
                onMyTaskChange={onMyTaskChange}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
              <CngBackdrop loading={loading} />
            </CngGridItem>
          </Grid>
        </CngGridItem>
        <ScrollTop>
          <Fab color='secondary' size='small' aria-label='scroll back to top'>
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </Grid>
    </Fragment>
  )
}

const ManageSIFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
})

export default ManageSIFormProperties
