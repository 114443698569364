import { Box, Paper } from '@material-ui/core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import Api from 'src/views/freightbooking/shared/api'
import SIBookingTypeComponent from '../../components/SIBookingTypeComponent'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import CodeMaintainanceApiUrls from 'src/apiUrls/CodeMaintainanceApiUrls'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import SIContactDetailsComponent from '../../components/SIContactDetailsComponent'
import SIContainerComponent from '../../components/SIContainerComponent'
import DocumentComponent from '../../components/DocumentComponent'
import SIDocumentComponent from '../../components/SIDocumentComponent'
import SIUploadInformationComponent from '../../components/SIUploadInformationComponent'
import FrbUtils from 'src/views/freightbooking/shared/Utils'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import EditSIButtonComponent from 'src/views/shippinginstruction/components/EditSIButtonComponent'
import SIPaymentInstructionComponent from '../../components/SIPaymentInstructionComponent'
import SIScheduleComponent from '../../components/SIScheduleComponent'
import SIShipmentComponent from '../../components/SIShipmentComponent'
import ViewSIDgCargoComponent from '../../components/ViewSIDgCargoComponent'
import ViewSIUploadDocumentComponent from 'src/views/shippinginstruction/components/ViewSIUploadDocumentComponent'
import SICargoComponent from '../../components/SICargoComponent'
import Utils from 'src/views/common/utils/Utils'
import SIDGCargoComponent from 'src/views/shippinginstruction/components/SIDGCargoComponent'
import WarningDialog from 'src/components/dialog/WarningDialog'
//import useNewBookingMakeValidationSchema from './useNewBookingMakeValidationSchema'
import { v4 as uuid } from 'uuid'
import GeneralStepperComponent from 'src/views/common/ui/GeneralStepperComponent'
import pathMap from 'src/paths/PathMap_ShippingInstruction'
import * as qs from 'query-string'
import ContactStatusFilterComponent from 'src/views/company-preference/addressbook/components/filters/ContactStatusFilterComponent'
import SIApi from 'src/views/shippinginstruction/shared/api'
import ViewSICargoComponent from '../../components/ViewSICargoComponent'
import { useFormContext } from 'react-hook-form'
import SICargoUploadDialog from '../../components/SICargoUploadDialog'
import moment from 'moment'
import QuickScroll from 'src/components/iconbutton/QuickScroll'
const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  table: { useDefaultNotification },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  // onsubmit validation works only if field error plus touched, touched is determined by fields(priority) and initialValues
  // saveDraft: true,
  bookingId: '',
  setFreightBookingId: '',
  bookingStatus: '',
  directSi: false,
  isCarrier: true,
  ...SIBookingTypeComponent.initialValues,
  ...SIScheduleComponent.initialValues,
  ...SIShipmentComponent.initialValues,
  ...SIContactDetailsComponent.initialValues,
  ...SIContainerComponent.initialValues,
  ...SICargoComponent.initialValues,
  ...SIPaymentInstructionComponent.initialValues
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
  // makeValidationSchema: DEFAULT_VALIDATION_SCHEMA
  //makeValidationSchema: makeValidationSchema}
}

const { CodeMaintenanceType } = constants

function FormFields({
  disabled,
  showNotification,
  loading,
  prevMenu,
  setLoading,
  onSubmitForm,
  validationSchema
}) {
  const { errors, touched, resetForm } = useFormikContext()
  const history = useHistory()
  const location = useLocation()
  const stateData = location.state
  const { id } = useParams()

  const [siCargoUploadDialogOpen, setSiCargoUploadDialogOpen] = useState()
  const [booking, setBooking] = useState({})
  const [uploadedSIList, setUploadedSIList] = useState([])
  const fbTranslatedTextsObject = FreightBookingTranslationText()
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const { reset } = useFormContext()
  // const [saveDraftField, , { setValue: setSaveDraft }] = useField('saveDraft')
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [warningDialogOpen, setWarningDialogOpen] = useState(false)
  const [switchDGDialogOpen, setSwitchDGDialogOpen] = useState(false)
  const [dgCargo, setDGCargo] = useState(false)
  const [showLocalCategory, setShowLocalCategory] = useState(false)
  const [isDirectSi, setIsDirectSi] = useState(false)
  const [siStatus, setSiStatus] = useState(null)
  const [reloadSIData, setReloadSIData] = useState(false)
  const [dgCargoes, setDGCargoes] = useState([])
  const [cargoes, setCargoes] = useState([])
  //const [fromSIReview, setFromSIReview] = useState(false)
  const [isDirectSIField, , { setValue: setIsDirectSIField }] =
    useField('directSi')
  const [cargoKeyRef, setCargoKeyRef] = useState(uuid())

  const [, , { setValue: setBookingId }] = useField('bookingId')
  const [, , { setValue: setFreightBookingId }] = useField('freightBookingId')

  //move this from BookingTypeComponent to share with Cargo component
  const [hazardousField, , { setValue: setHazardousField }] =
    useField('hazardous')

  //move this from ShipmentComponent to handle onChange in ScheduleComponent
  const [placeOfReceiptCodeField, , { setValue: setPlaceOfReceiptCodeField }] =
    useField('placeOfReceiptCode')
  const [
    placeOfDeliveryCodeField,
    ,
    { setValue: setPlaceOfDeliveryCodeField }
  ] = useField('placeOfDeliveryCode')
  const [placeOfReceiptField, , { setValue: setPlaceOfReceiptField }] =
    useField('placeOfReceipt')
  const [placeOfDeliveryField, , { setValue: setPlaceOfDeliveryField }] =
    useField('placeOfDelivery')
  const [cargoDeliveryDateField, ,] = useField('cargoDeliveryDate')

  const placeOfReceiptKeyRef = useRef(uuid())
  const placeOfDeliveryKeyRef = useRef(uuid())
  const polKeyRef = useRef(uuid())
  const podKeyRef = useRef(uuid())
  const paymentKeyRef = useRef(uuid())
  const countryCodeByPortCodeRef = useRef([])
  const isEdit = useRef(false)
  console.log(stateData)

  const { fetchRecords } = useServices()
  const { setFieldValue } = useFormikContext()
  const [shouldRender, setShouldRender] = useState(false)
  const { getValues, setError } = useFormContext()

  const setValidatedError = (errorPath, message) => {
    let finalError = null
    let errorAttr = ''
    if (errorPath.includes(".")) {
      let errorObj = {}
      const arrErrorPath = errorPath.split(".")
      errorObj[arrErrorPath[1]] = message
      if (arrErrorPath[0].includes("[")) {
        const matches = arrErrorPath[0].match(/\[(.*?)\]/)
        if (matches) {
          errorAttr = arrErrorPath[0].replace(matches[0], "")
          const number = matches[1]
          finalError = []
          for (let i = 0; i < number; i++) {
            finalError.push(null)
          }
          finalError.push(errorObj)
        }
      } else {
        errorAttr = arrErrorPath[0]
        finalError = errorObj
      }
    } else {
      errorAttr = errorPath
      finalError = { message: message }
    }
    if (errorAttr && finalError) {
      setError(errorAttr, finalError)
    }
  }

  const onConfirmReview = async () => {
    // if (!isEmpty(errors)) {
    //   setWarningDialogOpen(true)
    // }

    await setFieldValue('saveDraft', false)
    if (onSubmitForm) {
      const values = getValues()
      try {
        const validatedData = await validationSchema.validate(values)
        onSubmitForm(validatedData)
      } catch (error) {
        setValidatedError(error.path, error.message)
        setWarningDialogOpen(true)
      }
    }
    //submitForm()
  }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0
  }

  const onSaveChanges = async () => {
    // if (!isEmpty(errors)) {
    //   setWarningDialogOpen(true)
    //   // alert('There is error in your form. Please correct the error before submit the form')
    // }

    await setFieldValue('saveDraft', true)
    if (onSubmitForm) {
      const values = getValues()
      try {
        const validatedData = await validationSchema.validate(values)
        onSubmitForm(validatedData)
      } catch (error) {
        setValidatedError(error.path, error.message)
        setWarningDialogOpen(true)
      }
    }
    // submitForm()
  }

  const onDiscard = () => {
    setConfirmDialogOpen(true)
  }

  const discardSI = () => {
    history.push({
      pathname: pathMap.MANAGE_SI
    })
  }
  useEffect(() => {
    console.log('new si!')

    console.log(location.search)

    console.log(stateData)

    if (stateData != null && stateData.shippingInfo != null) {
      console.log(' this is an existing si')

      if (stateData && stateData.prevPage === SIUtils.Page.SIList) {
        console.log('getSIfromapi')
        setLoading(true)
        const siRequest = {
          shippingInfoId: stateData.shippingInfo.shippingInfoId,
          freightBookingId: stateData.shippingInfo.freightBookingId
        }
        SIApi.fetchSIDetailsById(
          fetchRecords,
          siRequest,
          populateBookingAndSIData
        )
      } else {
        console.log('getSIfromreview')
        stateData.shippingInfo.isCarrier = true
        setSiStatus(stateData.shippingInfo.siStatus)
        setBooking(stateData.shippingInfo)

        // Pre set billOfLadingNo if is NULL to bookingRefNo
        if (stateData.shippingInfo.isCarrier && stateData.shippingInfo.billOfLadingNo == null) {
          stateData.shippingInfo.billOfLadingNo = stateData.shippingInfo.bookingRefNo
        }
      }
      // SIApi.fetchUploadSIDocuments(
      //   fetchRecords,
      //   {
      //     shippingInfoId: stateData.shippingInfo.shippingInfoId
      //   },
      //   populateUploadSIDocuments
      // )
    } else if (location.search !== '') {
      console.log('external url')
      // const parsed = qs.parse(location.search)
      // console.log(parsed)
      // let bookingIdtset = parsed.id
      // let dockeytest = parsed.dockey

      // const bookingRequest = {
      //   bookingId: bookingIdtset,
      //   dockey: dockeytest
      // }
      // Api.fetchBookingDetailsById(
      //   fetchRecords,
      //   bookingRequest,
      //   populateBookingData
      // )
    }
  }, [])

  function populateBookingAndSIData(siAndBookingData) {
    //need to expand the data and set the values in side shippinginfo.booking to upper level
    console.log('siAndBookingData', siAndBookingData)
    siAndBookingData.isCarrier = true
    // Pre set billOfLadingNo if is NULL to bookingRefNo
    if (siAndBookingData.isCarrier && siAndBookingData.billOfLadingNo == null) {
      siAndBookingData.billOfLadingNo = siAndBookingData.bookingRefNo
    }
    setSiStatus(siAndBookingData.siStatus)
    setBooking(SIUtils.expandBookingToShippingInfo(siAndBookingData))
    setLoading(false)
  }

  // function populateBookingData(bookingData) {
  //   setBooking(preProcessBookingData(bookingData))
  // }

  // function preProcessBookingData(bookingData) {
  //   //1) as shipper/carrier remarks in booking and SI share the same field name, but the value could be different,
  //   //when loading booking for new si creation, need to shif the value in shipperRemarks and carrierRemarks
  //   bookingData.bookingShipperRemarks = bookingData.shipperRemarks
  //   bookingData.shipperRemarks = ''
  //   bookingData.bookingCarrierRemarks = bookingData.carrierRemarks
  //   bookingData.carrierRemarks = ''

  //   //2) pol terminal is converted to pol desc, pod terminal is converted to pod desc
  //   bookingData.polDesc = bookingData.portOfLoadingTerminal
  //   bookingData.podDesc = bookingData.portOfDischargeTerminal

  //   return bookingData
  // }

  useEffect(() => {
    console.log('booking changed')
    // if (!isEmpty(booking)) {
    placeOfReceiptKeyRef.current = uuid()
    placeOfDeliveryKeyRef.current = uuid()
    polKeyRef.current = uuid()
    podKeyRef.current = uuid()
    paymentKeyRef.current = uuid()

    // if(data){
    //     setFieldValue('hazardous', data.hazardous)
    // }

    // if(data){
    //     console.log(JSON.stringify(data))
    //     setFieldValue('shipmentType', data.shipmentType)
    //     setFieldValue('moveType', data.moveType)
    // }
    PopulateDataFromReview()
    //  setShouldRender(true)
    //  }
  }, [booking])

  function PopulateDataFromReview() {
    console.log('populateDataFromReview(): booking : ', booking)
    //as shipper remarks and carrier remarks share the same field name but can contain different value in booking and si
    //need to shuffle the name before populate value

    if (booking != null && !isEmpty(booking)) {
      console.log(
        'populate data representativeNamexxx ' + booking.representativeName
      )
      const dataArr = Object.entries(booking)
      dataArr.forEach(([key, val]) => {
        console.log('key: ' + key + ' val ' + val)
        if (SIUtils.dateTimeFields.includes(key)) {
          val = Utils.formatString(val, Utils.UI_FORMAT_DATE_TIME)
          if ((key === 'shippedOnBoardDate' || key === 'issueDate') && !val) {
            val = Utils.formatString(moment(new Date().getTime()).format(Utils.UI_FORMAT_DATE_TIME), Utils.UI_FORMAT_DATE_TIME)
          }
        } else if (SIUtils.dateFields.includes(key)) {
          val = Utils.formatString(val, Utils.UI_FORMAT_DATE)
        } //as we are using useArrayField for cargo/dg cargo, the setFieldValue cannot be null, hence need to convert null to [] for them
        else if (SIUtils.nonNullableArrayFields.includes(key)) {
          val = val === null ? [] : val
        }

        booking[key] = val
        // setFieldValue(key, val)
      })

      reset(booking)
      setUploadedSIList(booking.shippingInfoUploadDTO || [])
      setDGCargo(booking.hazardous)
      if (booking.hazardous) {
        setDGCargoes(booking.shippingInfoDGCargoes)
      } else {
        setCargoes(booking.shippingInfoCargoes)
      }
      setShowLocalCategory(portsIncludeSG())

      setIsDirectSi(booking.directSi || stateData.directSi)

      isEdit.current = true
    }
  }

  // if (!shouldRender) {
  //   return null
  // }

  function portsIncludeSG() {
    return booking.polCode == 'SGSIN' || booking.podCode == 'SGSIN'
  }

  const onDGchange = (event) => {
    console.log(event.target.checked)

    setSwitchDGDialogOpen(true)
  }

  const switchDG = async () => {
    setDGCargo(!dgCargo)
    setHazardousField(!dgCargo)
    setSwitchDGDialogOpen(false)
    //distory the existing cargo/dg cargo list
    await setFieldValue('shippingInfoCargoes', null)
    await setFieldValue('shippingInfoCargoes', null)

    //setCargoKeyRef(uuid())
  }

  const closeUploadCargoDialog = () => {
    setSiCargoUploadDialogOpen(false)
    setReloadSIData(!reloadSIData)
  }

  const newSISteps = [
    siTranslatedTextsObject.stepLabelFillupDetails,
    siTranslatedTextsObject.stepLabelReviewDetails,
    siTranslatedTextsObject.stepLabelFinish
  ]

  return (
    <Box id="top-level-box">
      {/* {'Error :' + JSON.stringify(errors)}
      {'prevMenu1 :' + prevMenu} */}
      <br />

      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <CngBackdrop loading={loading} />
      </CngGridItem>

      <Paper>
        <Box alignItems='center' pr={15} pl={15}>
          <GeneralStepperComponent steps={newSISteps} activeStep={0} />
        </Box>

        <Box p={5} className='page-content'>
          <Box>
            <SIBookingTypeComponent.FormBody
              isDirectSI={isDirectSi}
              // populateBookingFromDirect={populateBookingFromDirect}
              onDGchange={onDGchange}
              bookingTypeData={booking}
              isEdit={isEdit.current}
              showNotification={showNotification}
              isCarrier
            />
          </Box>

          <Box pt={5}>
            <SIScheduleComponent.FormBody
              isDirectSI={isDirectSi}
              placeOfReceiptKeyRef={placeOfReceiptKeyRef}
              placeOfDeliveryKeyRef={placeOfDeliveryKeyRef}
              countryCodeByPortCodeRef={countryCodeByPortCodeRef}
              setPlaceOfReceiptCodeField={setPlaceOfReceiptCodeField}
              setPlaceOfDeliveryCodeField={setPlaceOfDeliveryCodeField}
              setPlaceOfReceiptField={setPlaceOfReceiptField}
              setPlaceOfDeliveryField={setPlaceOfDeliveryField}
              isEdit={isEdit.current}
              polKeyRef={polKeyRef.current}
              podKeyRef={podKeyRef.current}
              setShowLocalCategory={setShowLocalCategory}
              isCarrier
              prevMenu={prevMenu}
            />
          </Box>

          <Box pt={5}>
            <SIContactDetailsComponent.FormBody
              isDirectSI={isDirectSi}
              isEdit={isEdit.current}
              showNotification={showNotification}
              isCarrier
            />
          </Box>

          <Box pt={5}>
            <SIShipmentComponent.FormBody
              placeOfReceiptKeyRef={placeOfReceiptKeyRef.current}
              placeOfDeliveryKeyRef={placeOfDeliveryKeyRef.current}
              countryCodeByPortCodeRef={countryCodeByPortCodeRef}
              placeOfReceiptCodeField={placeOfReceiptCodeField}
              setPlaceOfReceiptField={setPlaceOfReceiptField}
              setPlaceOfDeliveryField={setPlaceOfDeliveryField}
              placeOfDeliveryCodeField={placeOfDeliveryCodeField}
              isEdit={isEdit.current}
              isDirectSI={isDirectSi}
              isCarrier
              prevMenu={prevMenu}
            />
          </Box>
          <Box pt={5}>
            <SIContainerComponent.FormBody
              //  fromSIReview={fromSIReview}
              isDirectSI={isDirectSi}
              showNotification={showNotification}
              isEdit={isEdit.current}
              isCarrier
            />
          </Box>

          {dgCargo ? (
            <Box pt={5}>
              <SIDGCargoComponent.FormBody
                isEdit={isEdit.current}
                showLocalCategory={showLocalCategory}
                cargoKeyRef={cargoKeyRef}
                isDirectSI={isDirectSi}
                isCarrier
              />
            </Box>
          ) : (
            <Box pt={5}>
              <SICargoComponent.FormBody
                //  fromSIReview={fromSIReview}
                isDirectSI={isDirectSi}
                showNotification={showNotification}
                isEdit={isEdit.current}
                isCarrier
                shouldRender
                setShouldRender={setShouldRender}
                shippingInfoId={booking.shippingInfoId}
              />
            </Box>
          )}

          <Box pt={5}>
            <SIDocumentComponent.FormBody
              showNotification={showNotification}
              isEdit={isEdit.current}
              isDirectSI={isDirectSi}
            />
          </Box>

          {booking.shippingInfoId != undefined &&
            !dgCargo &&
            uploadedSIList.length > 0 && (
              <Box pt={5}>
                <ViewSIUploadDocumentComponent.FormBody
                  uploadedSIDocuments={uploadedSIList}
                />
              </Box>
            )}

          <Box pt={5}>
            <SIPaymentInstructionComponent.FormBody
              isEdit={isEdit.current}
              forceExpand
              isCarrier
              paymentKeyRef={paymentKeyRef.current}
            />
          </Box>
          <QuickScroll />
        </Box>
      </Paper>
      <Paper className='sticky-footer'>
        <Box alignItems='center'>
          <EditSIButtonComponent
            prevMenu={prevMenu}
            siStatus={siStatus}
            onSaveDraft={onSaveChanges}
            onConfirmReview={onConfirmReview}
            onDiscard={onDiscard}
          />
        </Box>
      </Paper>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={discardSI}
        content={siTranslatedTextsObject.dialogDiscardSIContent}
        okMsg={uiTranslatedTextsObject.ok}
        cancelMsg={uiTranslatedTextsObject.cancel}
        title={siTranslatedTextsObject.dialogDiscardSITitle}
      />

      <WarningDialog
        isDialogOpen={warningDialogOpen}
        closeDialog={() => setWarningDialogOpen(false)}
        okDialog={() => setWarningDialogOpen(false)}
        content={fbTranslatedTextsObject.formErrorWarning}
        okMsg={uiTranslatedTextsObject.ok}
      />

      <ConfirmDialog
        isConfirmDialogOpen={switchDGDialogOpen}
        closeDialog={() => setSwitchDGDialogOpen(false)}
        confirmDialog={switchDG}
        content={fbTranslatedTextsObject.dialogSwitchDGContent}
        okMsg={uiTranslatedTextsObject.ok}
        cancelMsg={uiTranslatedTextsObject.cancel}
        title={<h3>{uiTranslatedTextsObject.warning}</h3>}
      />

      <SICargoUploadDialog
        isDialogOpen={siCargoUploadDialogOpen}
        closeDialog={setSiCargoUploadDialogOpen}
        showNotification={showNotification}
        title={siTranslatedTextsObject.uploadCargo}
        shippingInfo={booking}
        setShippingInfo={setBooking}
        setPageLoading={setLoading}
        closeAndUpdateSI={closeUploadCargoDialog}
      />
    </Box>
  )
}

const ManageSIManageFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
  // toClientDataFormat: toClientDataFormat,
  // toServerDataFormat: toServerDataFormat
})

export default ManageSIManageFormProperties
