import React, { useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useParams, useLocation } from 'react-router-dom'

import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import ManageSIManageFormProperties from './ManageSIManageFormProperties'
import Utils from 'src/views/freightbooking/shared/Utils'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import CommonUtils from 'src/views/common/utils/Utils'
import useManageSIMakeValidationSchema from './useManageSIMakeValidationSchema'

import pathMap from 'src/paths/PathMap_ShippingInstruction'
import ShippingInstructionApiUrls from 'src/apiUrls/ShippingInstructionApiUrls.js'

const {
  form: { CngViewForm },
  table: { useDefaultNotification }
} = components

function ManageSIManagePage({ showNotification, prevMenu }) {
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const { createRecord } = useServices()
  const [loading, setLoading] = useState(false)
  const translatedTextsObject = ShippingInstructionTranslationText()
  const [booking, setBooking] = useState([])
  const bookingId = useRef()
  const freightBookingId = useRef()
  const historyData = location.state
  const { fetchRecords } = useServices()
  const validationSchema = useManageSIMakeValidationSchema()

  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  function onSubmit(data) {
    console.log('submit', data)

    SIUtils.convertDate(data)

    if (data['saveDraft']) {
      //if there is document, save document first then get the doc id and save draft
      console.log(data.pendingShippingInfoDocuments)
      if (
        data.pendingShippingInfoDocuments &&
        !Utils.isEmpty(data.pendingShippingInfoDocuments)
      ) {
        uploadDocumentAndSaveChanges(data)
      } else {
        saveChanges(data)
      }
    } else {
      // redirectToReviewPage(data)
      createReviewSI(data)
    }
  }

  function createReviewSI(data) {
    console.log(data)

    setLoading(true)
    console.log('reviewBooking')
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      setLoading(false)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        //redirct here

        redirectToReviewPage(data)
      }
    }

    createRecord.execute(
      ShippingInstructionApiUrls.REVIEW_SI,
      data,
      onSuccess,
      onError
    )
  }

  function uploadDocumentAndSaveChanges(data) {
    setLoading(true)
    console.log('uploadDocumentAndSaveDraft')

    var formData = new FormData()
    console.log(data.pendingShippingInfoDocuments)

    data.pendingShippingInfoDocuments.map((file, i) => {
      var fileBlob = file.file
      console.log(fileBlob)
      formData.append('fileContents', fileBlob) // name of the file upload box
      return null
    })

    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        //showSuccessNotification(`${translatedTextsObject.saveDraftSuccess} ${response.freightBookingId}`)

        let sigDocs = data.shippingInfoDocuments || [] //to store the final booking documents
        if (response && response.length > 0) {
          response.forEach((file, i) => {
            let siDoc = {
              docType: data.pendingShippingInfoDocuments[i].fields[0].value,
              filename: file.fileName,
              description: data.pendingShippingInfoDocuments[i].fields[1].value,
              docId: file.id,
              filesize: file.fileSize
            }

            sigDocs.push(siDoc)
          })
        }

        data.shippingInfoDocuments = sigDocs

        console.log(data)
        saveChanges(data)
      }
    }

    createRecord.execute(
      ShippingInstructionApiUrls.DOCUMENT_UPLOAD,
      formData,
      onSuccess,
      onError
    )
  }

  function onError(error) {
    console.log('connection-request error', error.message)
    setLoading(false)
    showErrorNotification(CommonUtils.getErrorMessage(error))
  }

  function saveChanges(data) {
    setLoading(true)
    console.log('saveDraft')
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      setLoading(false)
      if (response.errorMessages != null) {
        // if (response.errorMessages.length > 0) {
        showErrorNotification(response.errorMessages)
        // }
      } else {
        showSuccessNotification(`${translatedTextsObject.updateSuccessMsg}`)

        history.push({
          pathname: pathMap.MANAGE_SI
        })
      }
    }

    createRecord.execute(
      ShippingInstructionApiUrls.CARRIER_UPDATE_SI,
      data,
      onSuccess,
      onError
    )
  }

  function redirectToReviewPage(data) {
    history.push({
      pathname: pathMap.MANAGE_SI_MANAGE_SI_REVIEW,
      state: { data, prevMenu }
    })
  }

  return (
    // use CngViewForm here for customizable button set
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <ManageSIManageFormProperties.FormFields
          disabled={false}
          showNotification={showNotification}
          loading={loading}
          prevMenu={prevMenu}
          setLoading={setLoading}
          validationSchema={validationSchema}
          onSubmitForm={onSubmit}
        />
      }
      formikProps={{
        ...ManageSIManageFormProperties.formikProps,
        validationSchema,
      }}
    // toClientDataFormat={PollingFormProperties.toClientDataFormat}
    // toServerDataFormat={PollingFormProperties.toServerDataFormat}
    />
  )
}

export default ManageSIManagePage
