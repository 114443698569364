import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import CreateSIFormProperties from './ShipmentSearchFormProperties'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_ShippingInstruction'
import PanelSection from './PanelSection'

const {
  form: { CngViewForm }
} = components

function CreateSIPage({ showNotification }) {
  const { id } = useParams()
  const history = useHistory()

  function onSubmit(data) {
    alert('you cannot trigger this')
  }

  return (
    // use CngViewForm here for customizable button set
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <PanelSection.FormFields
          disabled={false}
          showNotification={showNotification}
        />
      }
      formikProps={{
        ...PanelSection.formikProps,
        onSubmit: onSubmit
      }}
      // toClientDataFormat={PollingFormProperties.toClientDataFormat}
      // toServerDataFormat={PollingFormProperties.toServerDataFormat}
    />
  )
}

export default CreateSIPage
