import { useHistory, useParams } from 'react-router-dom'

import React from 'react'
import ReviewSIFormProperties from './ReviewSIFormProperties'
import { components } from 'cng-web-lib'

const {
  form: { CngViewForm }
} = components

function ReviewSIPage({ showNotification }) {
  const { id } = useParams()
  const history = useHistory()

  function onSubmit(data) {
    console.log('submit', data)
  }

  return (
    // use CngViewForm here for customizable button set
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <ReviewSIFormProperties.FormFields
          disabled={false}
          showNotification={showNotification}
        />
      }
      formikProps={{
        ...ReviewSIFormProperties.formikProps,
        onSubmit: onSubmit
      }}
    />
  )
}

export default ReviewSIPage
