import { BasePage as CngBasePage } from 'cng-web-lib'
import ManageSIPage from './ManageSIPage'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_ShippingInstruction'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import CreateSIViewSIDraft from 'src/views/shippinginstruction/createsi/viewsidraft/CreateSIViewSIDraftPage'
import NewSIPage from 'src/views/shippinginstruction/createsi/newsi/NewSIPage'
import ReviewSIPage from 'src/views/shippinginstruction/createsi/reviewsi/ReviewSIPage'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import ManageSIManagePage from './manage/ManageSIManagePage'

function WrappedManageSIPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ShippingInstructionTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <ManageSIPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedManageSIViewSIPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ShippingInstructionTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <CreateSIViewSIDraft
          showNotification={showSnackbar}
          {...props}
          isCarrier
        />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedManageSIManagePage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ShippingInstructionTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <ManageSIManagePage
          showNotification={showSnackbar}
          {...props}
          prevMenu={SIUtils.Menus.manageSI}
        />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedManageSIReviewPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ShippingInstructionTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <ReviewSIPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedManageSIPage as ManageSIPage,
  WrappedManageSIViewSIPage as ManageSIViewSIPage,
  WrappedManageSIManagePage as ManageSIManagePage,
  WrappedManageSIReviewPage as ManageSIReviewPage,
  WrappedManageSIReviewPage as ManageSISubmitPage
}
