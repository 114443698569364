import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Fab,
  Grid,
  Toolbar
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { Box, Paper } from '@material-ui/core'
import { components, useServices } from 'cng-web-lib'
import React, { useEffect, useRef, useState } from 'react'
import CreateSIButton from 'src/components/button/CreateSIButton'
import Api from 'src/views/freightbooking/shared/api'
import SIApi from 'src/views/shippinginstruction/shared/api'
import ApiParser from 'src/views/freightbooking/shared/apiParser'
import SiApiParser from 'src/views/shippinginstruction/shared/apiParser'
import CreateSIBookingListComponent from '../components/CreateSIBookingListComponent'
import SIBookingListNoResult from '../components/SIBookingListNoResult'
import SIBookingListPageError from '../components/SIBookingListPageError'
import ButtonTabComponent from 'src/views/freightbooking/components/ButtonTabComponent'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import SIFilterPanelComponent from '../components/SIFilterPanelComponent'
import FreeTextSearchComponent from 'src/views/freightbooking/components/FreeTextSearchComponent'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { LensTwoTone } from '@material-ui/icons'
import { Paperclip } from 'react-feather'
import ResetFilterComponent from 'src/views/freightbooking/components/ResetFilterComponent'
import ScrollTop from 'src/views/freightbooking/components/ScrollTop'
import makeValidationSchema from 'src/views/freightbooking/shared/MakeValidationSchema'
import searchScheduleApiUrls from 'src/apiUrls/SearchScheduleApiUrls'
import { useLocation } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import TopBarSearchComponent from '../components/TopBarSearchComponent'
import GeneralButtonTabComponent from 'src/views/common/ui/GeneralButtonTabComponent'
import SIButtonTabComponent from '../components/SIButtonTabComponent'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
import CreateNewSITabButtonAndSortComponent from '../components/CreateNewSITabButtonAndSortComponent'
import Utils from 'src/views/common/utils/Utils'
import * as CalistaConstant from 'src/constants/CalistaConstant'
import pathMap from 'src/paths/PathMap_ShippingInstruction'
import { useHistory } from 'react-router-dom'
import ShowCountComponent from '../components/ShowCountComponent'
import CngaAlertLabel from 'src/components/label/CngAlertLabel'
import { populatePortDesc } from '../../common/ui/PopulatePortDescription'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngTextField, CngDateField }
  },
  CngGridItem
} = components

const initialValues = Object.freeze({
  dateRange: 'all',
  pendingClicked: true,
  filterText: '',
  sortCode: 1,
  shipmentCount: 0,
  draftsCount: 0,
  statusCodes: [
    CalistaConstant.BOOKING_STATUS_DRAFT,
    CalistaConstant.BOOKING_STATUS_AMENDED,
    CalistaConstant.BOOKING_STATUS_PENDING_APPROVAL
  ],
  status: {},
  partyIds: [],
  carriers: {},
  showArchived: true,
  statusTabChanged: true,
  hazardous: false,
  carRefNo: '',
  shipmentType: ''
})

function FormFields(props) {
  //move this from BookingTypeComponent to share with Cargo component
  const { disabled, showNotification, shouldHideMap } = props
  const { fetchRecords } = useServices()
  const history = useHistory()
  const location = useLocation()
  //   const { submitForm } = useFormikContext()
  const [statusCodesField, , { setValue: setStatusCodesField }] =
    useField('statusCodes')
  const [dateRangeField, , { setValue: setDateRangeField }] =
    useField('dateRange')
  const [statusField, , { setValue: setStatusField }] = useField('status')
  const [sortCodeField, , { setValue: setSortCodeField }] = useField('sortCode')
  const [, , { setValue: setSortByListField }] = useField('sortByList')
  const [, , { setValue: setShipmentClickedField }] =
    useField('shipmentClicked')
  const [filterTextField, , { setValue: setFilterTextField }] =
    useField('filterText')
  const [fromDirectSIPage, setFromDirectSIPage] = useState(
    location.state && location.state.fromDirectSIPage
  )

  const [searchCarRefNo, setSearchCarRefNo] = useState(
    !fromDirectSIPage ? '' : location.state.carRefNo
  )

  const [carriersField, , { setValue: setCarriersField }] = useField('carriers')
  const [showArchivedField, , { setValue: setShowArchivedField }] =
    useField('showArchived')
  const [partyIdsField, , { setValue: setPartyIdsField }] = useField('partyIds')
  const [statusTabChangedField, , { setValue: setStatusTabChangedField }] =
    useField('statusTabChanged')
  const [hazardousField, , { setValue: setHazardousField }] =
    useField('hazardous')
  const [hazardous, setHazardous] = useState(false)
  const [carRefNoField, , { setValue: setCarRefNoField }] = useField('carRefNo')
  const [shipmentTypeField, , { setValue: setShipmentTypeField }] =
    useField('shipmentType')
  const translatedTextsObject = ShippingInstructionTranslationText()

  const defaultDateRange = 'all' //default value to query via API
  const VALUE_ALL = 'ALL'
  const defaultShowArchived = true

  const [dateRange, setDateRange] = useState()
  const [sortByList, setSortByList] = useState()
  const [filterText, setFilterText] = useState()
  const [bookingList, setBookingList] = useState([])
  //const [originalBookingList, setOriginalBookingList] = useState([])
  const [showArchived, setShowArchived] = useState()
  const [loading, setLoading] = useState(true)
  const [shownCount, setShownCount] = useState(0)

  const gotoDirectSIPage = () => {
    history.push({
      pathname: pathMap.CREATE_SI_NEW_SI,
      state: { directSI: true }
    })
  }

  const clickShipment = () => {
    console.log('clickShipment')
    //in shipment tab, click shipment , nothing will happen
  }

  const clickDrafts = () => {
    console.log('clickDrafts')
    //in shipment tab, click draft, tab will switch to "draft"
    props.setShipmentClicked(false)
    setStatusTabChangedField(true)
  }

  const handleSortByChange = (e) => {
    console.log('handleSortByChange')
    //alert(e.target.value)
    if (e.target.value != null && e.target.value != 0) {
      setSortCodeField(e.target.value)
      setStatusTabChangedField(false)
      manualRefresh()
      //submitForm()
    }
  }

  const handelFreeTextSearch = () => {
    setStatusTabChangedField(true)
    manualRefresh()
  }

  const handleClearFiltreAndTextButtonClick = () => {
    console.log('handleClearFiltreAndTextButtonClick')

    // setFilterText('')
    // setFilterTextField('')
    setCarRefNoField('')
    setHazardousField(false)
    setHazardous(false)
    setShipmentTypeField('')
    handleClearAllButtonClick()
  }
  const handleClearAllButtonClick = () => {
    console.log('handleClearAllButtonClick')

    setDateRange(defaultDateRange)
    setDateRangeField(defaultDateRange)
    // let newStatus = [...status]
    // newStatus = newStatus.map((item) => ({
    //   ...item,
    //   checked: true
    // }))

    // console.log(newStatus)
    // setStatus(newStatus)
    // setStatusField(newStatus)

    let newCarriers = [...carriers]
    newCarriers = newCarriers.map((item) => ({
      ...item,
      checked: true
    }))

    setCarriers(newCarriers)
    setCarriersField(newCarriers)

    setShowArchivedField(defaultShowArchived)
    setShowArchived(defaultShowArchived)

    // let newStatusCode = getNewStatuCode(newStatus)
    // console.log(newStatusCode)

    // setStatusCodesField(newStatusCode)

    setStatusTabChangedField(true) //force carrier list load from api
    manualRefresh()
    //submitForm()
  }

  const handleCarRefNoTextChange = (e) => {
    console.log(e.target)
    setFilterText(e.target.value)
    setCarRefNoField(e.target.value)
  }

  const handleHazardousChange = (e) => {
    console.log(e.target.name)
    console.log(e.target.checked)
    setHazardousField(e.target.checked)
    setHazardous(e.target.checked)
  }

  const handleShipmentTypeChange = (e) => {
    console.log(e)
    setShipmentTypeField(e)
  }

  const onStatusCheckBoxChange =
    (index) =>
    ({ target: { checked } }) => {
      console.log('handleStatusCheckBoxChange')
      let newStatus = [...status]
      const currentStatus = status[index]
      if (currentStatus.statusDesc !== VALUE_ALL) {
        const allStatus = status[status.length - 1]

        newStatus[index] = { ...currentStatus, checked: !currentStatus.checked }
        newStatus[status.length - 1] = { ...allStatus, checked: false }
      } else {
        newStatus = newStatus.map((item) => ({
          ...item,
          checked: !currentStatus.checked
        }))
      }

      setStatus(newStatus)
      setStatusField(newStatus)
      console.log(newStatus)

      //get new status code and list
      let newStatusCode = getNewStatuCode(newStatus)
      console.log(newStatusCode)

      setStatusCodesField(newStatusCode)
      setStatusTabChangedField(true) //force carrier list load from api
      manualRefresh()
      //submitForm()
    }

  function getNewStatuCode(newStatus) {
    let temp = []
    newStatus
      .filter((v) => v.checked == true)
      .map((v) =>
        // console.log(v.statusCode)
        // let temp2 = v.statusCode
        temp.push(v.statusCode)
      )

    return temp
  }

  const showArchivedChange = (e) => {
    console.log('showArchivedChange')
    console.log(e.target.checked)
    setShowArchivedField(e.target.checked)
    setStatusTabChangedField(true) //force carrier list load from api
    manualRefresh()
    //submitForm()
  }

  const dateRangeChange = (e) => {
    console.log(e.target.defaultValue)
    //setStatusTabChangedField(true)
    setDateRange(e.target.defaultValue)
    setDateRangeField(e.target.defaultValue)

    manualRefresh()
  }

  const onCarrierCheckBoxChange =
    (index) =>
    ({ target: { checked } }) => {
      console.log('onCarrierCheckBoxChange ' + index)
      let newCarriers = [...carriers]
      const carrier = carriers[index]
      console.log(carrier.partyId)
      if (carrier.nameOth !== VALUE_ALL) {
        const allCarrier = carriers[carriers.length - 1]

        newCarriers[index] = { ...carrier, checked: !carrier.checked }
        newCarriers[carriers.length - 1] = { ...allCarrier, checked: false }
      } else {
        newCarriers = newCarriers.map((item) => ({
          ...item,
          checked: !carrier.checked
        }))
      }

      setCarriers(newCarriers)
      setCarriersField(newCarriers)

      let temp = []
      newCarriers
        .filter((v) => v.checked == true)
        .map((v) =>
          // console.log(v)
          // let temp2 = v.partyId
          temp.push(v.partyId)
        )

      console.log(temp)

      setPartyIdsField(temp)
      setStatusTabChangedField(false)

      manualRefresh()
      //  submitForm()
    }

  // const performFilter = (newCarriers) => {
  //     console.log("performFilter")
  //     console.log(originalBookingList)
  //     const newBookingList = [...originalBookingList]

  //     setBookingList(newBookingList.filter((booking) => {
  //         let item =
  //             newCarriers &&
  //             newCarriers.find(
  //                 (newCarrier) =>
  //                     newCarrier.partyId === booking.carrierPartyId && newCarrier.checked
  //             )
  //         return item === undefined ? false : true
  //     })
  //     )

  // }

  //advanced search
  let searchCriteria = {
    //filterText: filterTextField.value,
    dateRange: dateRangeField.value,
    statusCodes: [5102],
    showArchived: showArchivedField.value,
    partyIds: partyIdsField.value,
    sortCode: Utils.isEmptyString(sortCodeField.value)
      ? 1
      : sortCodeField.value,
    advanceSearch: true,
    hazardous: hazardousField.value,
    carRefNo: !fromDirectSIPage ? carRefNoField.value : searchCarRefNo,
    shipmentType: shipmentTypeField.value
  }

  let criteriaForCount = {
    filterText: '',
    dateRange: defaultDateRange,
    statusCodes: [CalistaConstant.BOOKING_STATUS_APPROVED],
    showArchived: defaultShowArchived,
    partyIds: initialValues.partyIds,
    sortCode: 1
  }

  const [carriers, setCarriers] = useState([])

  const [status, setStatus] = useState([])
  const [pendingStatusList, setPendingStatusList] = useState([])
  const [processedStatusList, setProcessedStatusList] = useState([])
  const [shipmentCount, setShipmentCount] = useState(0)
  const [draftsCount, setDraftsCount] = useState(0)
  const [shipmentClicked, setShipmentClicked] = useState()
  const [loadingCriteria, setLoadingCriteria] = useState()
  const [pageError, setPageError] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [portcodeToDescList, setPortcodeToDescList] = useState([])

  const manualRefresh = () => {
    setRefresh(!refresh)
    setLoading(true)
    setBookingList([])
    setShownCount(0)
  }

  useEffect(() => {
    if (location.state && location.state.fromDirectSIPage) {
      setCarRefNoField(searchCarRefNo)
      setFromDirectSIPage(false)
    }
  }, [])

  useEffect(() => {
    console.log(sortByList)
    setPageError(false)
    setSortCodeField(
      sortCodeField.value == null || sortCodeField.value == ''
        ? 1
        : sortCodeField.value
    )

    if (sortByList == null) {
      //fetch sort code when the page is first loaded, the result will remain static
      getSortingType()
    }

    //get count for each tabs
    fetchShipmentCountFromAPI()
    fetchDraftsCountFromAPI()

    fetchPartyIdFromAPI()

    //get party id based on status code, date range and sort code
  }, [refresh])

  function fetchDraftsCountFromAPI() {
    SIApi.fetchStatusCodeAndCount(fetchRecords, populateDraftsCount, onCountError)
  }


  function onCountError(error){
    console.log(error)
  }
  function populateDraftsCount(dat) {
    // const data = SiApiParser.parseDraftCount(dat)
    console.log(dat)
    setDraftsCount(dat.pendingSubmissionCount)
  }

  function populatePartyId(dat) {
    let FromApi = ApiParser.parsePartyId(dat)
    if (FromApi[0] !== undefined) {
      let newRecord = { ...FromApi[0] }
      newRecord.nameOth = 'ALL'
      newRecord.bookingCount = FromApi.reduce((a, b) => a + b.bookingCount, 0)
      FromApi = [...FromApi, newRecord]

      console.log(FromApi)

      let partyIdsForSearch = getPartyIdForBookingList(dat.content)
      console.log(partyIdsForSearch)
      getBookingList()
    } else {
      setLoading(false)
      setPartyIdsField(
        Utils.isEmptyString(partyIdsField.value) ? [] : partyIdsField.value
      )
    }

    if (reloadCarrierCheckBoxNeeded()) {
      setCarriers(FromApi)
      setCarriersField(FromApi)
    } else {
      setCarriers(carriersField.value)
      // setCarriersField(location.state.carriers)
    }
  }
  function fetchPartyIdFromAPI() {
    Api.fetchPartyId(fetchRecords, searchCriteria, populatePartyId, onError)
  }

  function reloadCarrierCheckBoxNeeded() {
    return statusTabChangedField.value
  }

  function getPartyIdForCount(carrierList) {
    console.log('getPartyIdForCount')
    let partyIdss = []

    console.log('loading party id from api')

    carrierList.map((v) => {
      console.log(v.partyId)
      partyIdss.push(v.partyId)
    })

    criteriaForCount.partyIds = partyIdss

    return partyIdss
  }

  function getPartyIdForBookingList(carrierList) {
    console.log('getPartyIdForBookingList')
    let partyIdss = []
    if (reloadCarrierCheckBoxNeeded()) {
      console.log('loading party id from api')

      carrierList.map((v) => {
        console.log(v.partyId)
        partyIdss.push(v.partyId)
      })

      searchCriteria.partyIds = partyIdss
      setPartyIdsField(partyIdss)
    } else {
      console.log('loading party id from hisotry')
      setPartyIdsField(partyIdsField.value)
    }
    return partyIdss
  }

  function populateShipmentCount(dat) {
    console.log('populateShipmentCount', dat)
    setShipmentCount(dat.totalElements)
    // const data = ApiParser.parseStatusCodeAndCount(dat)
    // setShipmentCount(data.processedCount)
    // setStatus(
    //   Utils.isEmptyObject(statusField.value)
    //     ? data.pendingList
    //     : statusField.value
    // )

    // setStatusField(
    //   location.state == null ? data.pendingList : location.state.status
    // )

    // setPendingStatusList(data.pendingList)
    // setProcessedStatusList(data.processedList)
    // console.log(shipmentClicked)
    //let statusCodes = pendingClicked ? pendingStatusCode : processedStatusCode
  }

  function fetchShipmentCountFromAPI() {
    // Api.fetchStatusCodeAndCount(
    //   //this api need to change
    //   fetchRecords,
    //   populateShipmentCount,
    //   onError
    // )

    //as there is no isolated end point for getting the count of confirmed booking
    //will use the endpoint search-booking/booking-list/get with no criteria except status = confirmed

    Api.fetchPartyId(
      fetchRecords,
      criteriaForCount,
      populatePartyIdForCount,
      onError
    )
  }

  function getShipmentCount() {
    Api.fetchBookingList(
      fetchRecords,
      criteriaForCount,
      populateShipmentCount,
      onError
    )
  }

  function populatePartyIdForCount(dat) {
    let FromApi = ApiParser.parsePartyId(dat)
    if (FromApi[0] !== undefined) {
      let newRecord = { ...FromApi[0] }
      newRecord.nameOth = 'ALL'
      newRecord.bookingCount = FromApi.reduce((a, b) => a + b.bookingCount, 0)
      FromApi = [...FromApi, newRecord]

      console.log(FromApi)

      let partyIdsForSearch = getPartyIdForCount(dat.content)
      console.log(partyIdsForSearch)
      getShipmentCount()
    }
  }

  function populateSortingType(dat) {
    const list = ApiParser.parseSortingType(dat)
    setSortByList(list)
    setSortByListField(list)
  }

  function onError(error) {
    //console.log('connection-request error', error.message)
    setPageError(true)
    setLoading(false)
  }

  function getLeftCount() {
    let count = props.shipmentClicked
      ? shipmentCount - shownCount
      : draftsCount - shownCount
    return count
  }

  function getSortingType() {
    Api.fetchSortingType(fetchRecords, populateSortingType, onError)
  }

  function populateBookingList(data) {
    setBookingList(data.content)
    // setOriginalBookingList(data.content)
    populatePortDesc(data.content, fetchRecords, setPortcodeToDescList)
    setLoading(false)
    setShownCount(data.totalElements)
  }

  useEffect(() => {
      let tempBookingList = [...bookingList]
      tempBookingList.forEach((obj) => {
          obj.placeOfDelivery = portcodeToDescList[obj.placeOfDeliveryCode] || obj.placeOfDelivery
          obj.placeOfReceipt = portcodeToDescList[obj.placeOfReceiptCode] || obj.placeOfReceipt
      })
      setBookingList(tempBookingList)
  }, [portcodeToDescList])

  function getBookingList() {
    Api.fetchBookingListEnhanced(
      fetchRecords,
      searchCriteria,
      populateBookingList,
      onError
    )
    setLoadingCriteria(searchCriteria)
  }

  function showNoResultPage() {
    return (
      bookingList.length == 0 &&
      draftsCount === 0 &&
      getLeftCount() == 0 &&
      !loading
    )
  }

  return (
    <Card className='page-content'>
      <Box style={{ display: pageError ? 'inline' : 'none' }}>
        <SIBookingListPageError refreshAction={manualRefresh} />
      </Box>
      <Box
        style={{
          display: showNoResultPage() && !pageError ? 'inline' : 'none'
        }}
      >
        <SIBookingListNoResult createSI refreshAction={manualRefresh} />
      </Box>
      <Box
        style={{ display: showNoResultPage() || pageError ? 'none' : 'inline' }}
      >
        <Grid container xs={12} ms={12} justify='center'>
          <CngGridItem xs={12} ms={12}>
            <Card>
              <CardContent>
                <TopBarSearchComponent.FormBody
                  handleInputChange={handleCarRefNoTextChange}
                  handelFreeTextSearch={handelFreeTextSearch}
                  handleHazardousChange={handleHazardousChange}
                  handleShipmentTypeChange={handleShipmentTypeChange}
                  hazardous={hazardous}
                />
              </CardContent>
            </Card>
          </CngGridItem>
          <CngGridItem xs={9} ms={9}>
            <Card>
              <CardContent>
                <Grid container xs={12} ms={12} justify='center' spacing={2}>
                  <CngGridItem xs={12} ms={12}>
                    <CreateNewSITabButtonAndSortComponent.FormBody
                      firstTabLabel={translatedTextsObject.shipmentOverview}
                      secondTabLabel={translatedTextsObject.drafts}
                      firstTabCount={shipmentCount}
                      secondTabCount={draftsCount}
                      draftsCount={draftsCount}
                      shownCount={shownCount}
                      firstTabClicked={props.shipmentClicked}
                      firstTabOnClick={clickShipment}
                      secondTabOnClick={clickDrafts}
                      sortByList={sortByList}
                      handleSortByChange={handleSortByChange}
                    />
                  </CngGridItem>

                  <CngGridItem xs={10} ms={10}>
                    <CngaAlertLabel
                      alertNote={translatedTextsObject.createSINote}
                    />
                  </CngGridItem>
                  <CngGridItem xs={2} ms={2}>
                    <Grid
                      container
                      alignItems='center'
                      style={{ height: '100%' }}
                    >
                      <CreateSIButton direct onClick={gotoDirectSIPage} />
                    </Grid>
                  </CngGridItem>
                  <CngGridItem xs={6} ms={6} alignItems='left'>
                    <ShowCountComponent
                      shownCount={shownCount}
                      totalCount={
                        props.shipmentClicked ? shipmentCount : draftsCount
                      }
                    />
                  </CngGridItem>
                  <CngGridItem xs={6} ms={6}></CngGridItem>
                  <CngGridItem xs={12} ms={12}>
                    <Box>
                      <CreateSIBookingListComponent.FormBody
                        searchCriteria={loadingCriteria}
                        bookingList={bookingList}
                        showNotification={showNotification}
                        handleClearAllButtonClick={
                          handleClearFiltreAndTextButtonClick
                        }
                        leftCount={getLeftCount()}
                        setPageError={setPageError}
                      />
                    </Box>
                  </CngGridItem>
                </Grid>
              </CardContent>
            </Card>
          </CngGridItem>
          <CngGridItem xs={3} ms={3}>
            <Card>
              <CardContent>
                <Grid container xs={12} ms={12} spacing={2}>
                  <CngGridItem xs={12} ms={12}>
                    <SIFilterPanelComponent
                      handleClearAllButtonClick={handleClearAllButtonClick}
                      onStatusCheckBoxChange={onStatusCheckBoxChange}
                      showArchivedChange={showArchivedChange}
                      onCarrierCheckBoxChange={onCarrierCheckBoxChange}
                      dateRange={dateRangeField.value}
                      carriers={carriers}
                      status={status}
                      showArchived={showArchived}
                      dateRangeChange={dateRangeChange}
                    />
                  </CngGridItem>
                  <CngGridItem
                    xs={12}
                    sm={9}
                    shouldHide={loading ? false : true}
                  >
                    <CngBackdrop loading={loading} />
                  </CngGridItem>
                </Grid>
              </CardContent>
            </Card>
          </CngGridItem>
        </Grid>
      </Box>
      {/* <Box justifyContent="center">
                <Box>
                    <Toolbar id="back-to-top-anchor" />
                    <Grid container xs={12} ms={12} spacing={2} justify="center" style={{ backgroundColor: "red" }}>

                        <CngGridItem xs={12} ms={12} >
                        </CngGridItem>
                        <CngGridItem xs={9} ms={9} >
                            <Card>
                                <CardContent>
                                  
                                    <CngGridItem xs={12} ms={12} >
                                        <ButtonTabComponent.FormBody
                                            pendingCount={pendingCount}
                                            processedCount={processedCount}
                                            shownCount={shownCount}
                                            pendingClicked={pendingClicked}
                                            clickPending={clickPending}
                                            clickProcessed={clickProcessed}
                                            sortByList={sortByList}
                                            handleSortByChange={handleSortByChange} />
                                    </CngGridItem>

                                    <CngGridItem xs={12} ms={12} >
                                       
                                        <Box>
                                            <BookingListComponent.FormBody
                                                searchCriteria={loadingCriteria}
                                                bookingList={bookingList}
                                                showNotification={showNotification}
                                                handleClearAllButtonClick={handleClearFiltreAndTextButtonClick}
                                                leftCount={getLeftCount()} />
                                        </Box>
                                    </CngGridItem>

                                </CardContent>
                            </Card>
                           
                        </CngGridItem>

                        <CngGridItem sm={3} xs={12} >
                            <Grid container xs={12} ms={12} spacing={2}>
                                <CngGridItem xs={12} ms={12} >
                                    <FreeTextSearchComponent.FormBody
                                        handleInputChange={handleFilterTextChange}
                                        filterText={filterText}
                                    />
                                </CngGridItem>
                                <CngGridItem xs={12} ms={12} >
                                    <FilterPanelComponent
                                        handleClearAllButtonClick={handleClearAllButtonClick}
                                        onStatusCheckBoxChange={onStatusCheckBoxChange}
                                        showArchivedChange={showArchivedChange}
                                        onCarrierCheckBoxChange={onCarrierCheckBoxChange}
                                        dateRange={location.state == null ? defaultDateRange : location.state.dateRange}
                                        carriers={carriers}
                                        status={status}
                                        showArchived={showArchived}
                                    />
                                </CngGridItem>
                                <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
                                    <CngBackdrop loading={loading} />
                                </CngGridItem>

                            </Grid>
                        </CngGridItem>
                        <ScrollTop>
                            <Fab color="secondary" size="small" aria-label="scroll back to top">
                                <KeyboardArrowUpIcon />
                            </Fab>
                        </ScrollTop>
                    </Grid>
                </Box>
            </Box> */}
    </Card>
  )
}

const ShipmentSearchFormProperties = Object.freeze({
  initialValues: initialValues,
  FormFields: FormFields
})

export default ShipmentSearchFormProperties
