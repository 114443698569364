import {
  Box,
  Card,
  Paper,
  Grid,
  Typography,
  Avatar,
  Chip,
  CardActionArea
} from '@material-ui/core'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import React, { useEffect, useState } from 'react'
import ViewBookingTypeComponent from 'src/views/freightbooking/components/ViewBookingTypeComponent'
import ViewCargoComponent from 'src/views/freightbooking/components/ViewCargoComponent'
import ViewDgCargoComponent from 'src/views/freightbooking/components/ViewDgCargoComponent'
import ViewContainerComponent from 'src/views/freightbooking/components/ViewContainerComponent'
import ViewScheduleComponent from 'src/views/freightbooking/components/ViewScheduleComponent'
import ViewShipmentComponent from 'src/views/freightbooking/components/ViewShipmentComponent'
import ViewContactComponent from 'src/views/freightbooking/components/ViewContactComponent'
import ViewPaymentComponent from 'src/views/freightbooking/components/ViewPaymentComponent'
import { useServices, components } from 'cng-web-lib'
import ViewLastModifiedComponent from 'src/views/freightbooking/components/ViewLastModifiedComponent'
import DocumentDetails from 'src/views/freightbooking/components/DocumentDetails'
import ComponentWrapper from 'src/views/freightbooking/components/ComponentWrapper'
import HeaderBarTop from 'src/views/freightbooking/components/HeaderBarTop'
import Hazardous from 'src/components/button/Hazardous.js'
import BookingActionMenu from 'src/views/freightbooking/components/BookingActionMenu'
import CommonUtils from 'src/views/common/utils/Utils'
import ViewDocumentComponent from 'src/views/freightbooking/components/ViewDocumentComponent'
import AuditTrailTableComponent from 'src/components/auditTrail/AuditTrailTableComponent'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import * as CalistaConstant from 'src/constants/CalistaConstant'
import Utils from 'src/views/freightbooking/shared/Utils'
import apiParser from 'src/views/freightbooking/shared/apiParser'
import Api from 'src/views/freightbooking/shared/api'
import CreateSIButton from 'src/components/button/CreateSIButton'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import pathMap from 'src/paths/PathMap_ShippingInstruction'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import QuickScroll from 'src/components/iconbutton/QuickScroll'
const {
  CngGridItem,
  button: { CngSecondaryButton },
  table: { useDefaultNotification }
} = components

function FormFields(props) {
  const { booking, ports, auditList, userCriteria } = props
  const translatedTextsObject = FreightBookingTranslationText()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()

  const location = useLocation()
  const bookStatus = location.state.bookStatus

  const containers = booking.bookingContainers || []
  const cargos = booking.bookingCargoes || []
  const dgCargos = booking.bookingDGCargoes || []
  const documents = booking.bookingDocuments || []
  const history = useHistory()
  const [lastModifiedDate, setLastModifiedDate] = useState()
  const [lastModifiedText, setLastModifiedText] = useState()
  const [auditBooking, setAuditBooking] = useState({})
  const { fetchRecords } = useServices()
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(props.showNotification)

  useEffect(() => {
    switch (bookStatus.toString()) {
      case CalistaConstant.BOOKING_STATUS_APPROVED:
      case CalistaConstant.BOOKING_STATUS_AMENDED:
        setLastModifiedText(translatedTextsObject.bookingConfirmedOn)
        setLastModifiedDate(booking.updatedDate)
        break
      case CalistaConstant.BOOKING_STATUS_CANCELLED:
      case CalistaConstant.BOOKING_STATUS_REJECTED:
      case CalistaConstant.BOOKING_STATUS_PENDING_APPROVAL:
        setLastModifiedText(translatedTextsObject.bookingRequestedOn)
        setLastModifiedDate(booking.submittedDate)
        break
      case CalistaConstant.BOOKING_STATUS_DRAFT:
        setLastModifiedText(translatedTextsObject.bookingCreatedOn)
        setLastModifiedDate(booking.createdDate)
        break
      default:
        break
    }
  }, [props])

  const gotoCreateSI = () => {
    history.push({
      pathname: pathMap.CREATE_SI
    })
  }

  const createNewSI = () => {
    var totalCount = 0
    if (!Utils.isEmpty(containers)) {
      containers.forEach((container) => {
        totalCount = totalCount + parseInt(container.containerQty)
      })

      if (totalCount < 1000) {
        history.push({
          pathname: pathMap.CREATE_SI_NEW_SI,
          state: { booking: booking }
        })
      } else {
        showErrorNotification(
          `${siTranslatedTextsObject.cannotCreateSIMsg}${SIUtils.maxContainerNo}`
        )
      }
    }
  }

  useEffect(() => {
    if (
      auditList &&
      auditList.length > 1 &&
      bookStatus == CalistaConstant.BOOKING_STATUS_APPROVED
    ) {
      let rightVersion = auditList[0].frozenVersion
      let leftVersion = auditList[1].frozenVersion

      const versions = {
        leftVersion: leftVersion,
        rightVersion: rightVersion
      }
      Api.fetchAuditTrailCompare(
        fetchRecords,
        versions,
        onAuditTrailLoadSuccess
      )
    }
  }, [auditList])

  function onAuditTrailLoadSuccess(data) {
    setAuditBooking(data)
  }

  return (
    <Box id="top-level-box">
      <Paper>
        <HeaderBarTop booking={booking}></HeaderBarTop>
        <Box p={5} borderTop={1} borderBottom={1} className='border-light-blue'>
          <Grid
            container
            xs={12}
            sm={12}
            alignItems='center'
            style={{ marginBottom: 40 }}
          >
            <Grid container xs={7}>
              <ViewLastModifiedComponent
                text={lastModifiedText}
                date={lastModifiedDate}
              />
            </Grid>
            <Grid
              container
              xs
              direction='row'
              justify='flex-end'
              alignItems='center'
            >
              <Box>
                <img
                  src={`${process.env.PUBLIC_URL}/static/images/Logo_CALISTA.svg`}
                ></img>
              </Box>
              <Box className={'boldLabel'}>
                &nbsp;reference number: {booking.freightBookingId}
              </Box>
            </Grid>
          </Grid>
          <Grid container xs={12} sm={12} justify='space-between' spacing={2}>
            <Grid item xs={4}>
              {booking.shipmentType && (
                <Box>
                  <ViewBookingTypeComponent.FormBody
                    bookingTypeData={booking}
                  />
                </Box>
              )}
            </Grid>
            {booking.carrierRemarks && (
              <Grid item xs={8}>
                <ComponentWrapper
                  header={translatedTextsObject.carrierComments}
                >
                  <Box
                    px={2}
                    py={3}
                    style={CommonUtils.auditHighLight(auditBooking, [
                      'carRemarks'
                    ])}
                  >
                    <Typography>{booking.carrierRemarks}</Typography>
                  </Box>
                </ComponentWrapper>
              </Grid>
            )}
          </Grid>
          <Box pt={5}>
            <ViewScheduleComponent.FormBody
              scheduleData={booking}
              ports={ports}
              showDetails
              auditBooking={auditBooking}
              isCarrier
            />
          </Box>

          {booking.moveType == null ? (
            <Box></Box>
          ) : (
            <Box pt={5}>
              <ViewShipmentComponent.FormBody
                shipmentData={booking}
                ports={ports}
                showDetails
                auditBooking={auditBooking}
              />
            </Box>
          )}

          <Box pt={5}>
            <ViewContainerComponent.FormBody
              containers={containers}
              auditBooking={auditBooking}
            />
          </Box>

          {cargos.length > 0 && (
            <Box pt={5}>
              <ViewCargoComponent.FormBody cargo={cargos} showDetails />
            </Box>
          )}
          {dgCargos.length > 0 && (
            <Box pt={5}>
              <ViewDgCargoComponent.FormBody cargo={dgCargos} showDetails />
            </Box>
          )}

          {documents.length > 0 && (
            <Box pt={5}>
              <ViewDocumentComponent.FormBody
                document={documents}
                auditBooking={auditBooking}
              />
            </Box>
          )}
          <Box pt={5}>
            <ViewContactComponent.FormBody
              contactData={booking}
              auditBooking={auditBooking}
            />
          </Box>
          <Box pt={5}>
            <ViewPaymentComponent.FormBody paymentData={booking} />
          </Box>
          <Box pt={5}>
            <AuditTrailTableComponent.FormBody
              booking={booking}
              ports={ports}
              auditList={auditList}
              userCriteria={userCriteria}
            />
          </Box>

          <Box alignItems='center'>
            <Box pl={5} pr={5} pt={4} pb={4}>
              <Grid container xs={12} sm={12}>
                <Grid xs={6} sm={6} justify='flex-start'>
                  <CngSecondaryButton onClick={gotoCreateSI}>
                    {siTranslatedTextsObject.back}
                  </CngSecondaryButton>
                </Grid>
                {!booking.closed && (
                  <Grid container xs={6} sm={6} justify='flex-end'>
                    <Box pr={2}>
                      <CreateSIButton onClick={createNewSI} />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
          <QuickScroll />
        </Box>
      </Paper>
    </Box>
  )
}

const CreateSIReviewApproved = Object.freeze({
  // formikProps: FORMIK_PROPS,
  FormFields: FormFields
  // toClientDataFormat: toClientDataFormat,
  // toServerDataFormat: toServerDataFormat
})

export default CreateSIReviewApproved
