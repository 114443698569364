import { Box, Paper } from '@material-ui/core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import Api from 'src/views/freightbooking/shared/api'
import SIBookingTypeComponent from '../../components/SIBookingTypeComponent'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import CodeMaintainanceApiUrls from 'src/apiUrls/CodeMaintainanceApiUrls'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import SIContactDetailsComponent from '../../components/SIContactDetailsComponent'
import SIContainerComponent from '../../components/SIContainerComponent'
import DocumentComponent from '../../components/DocumentComponent'
import SIDocumentComponent from '../../components/SIDocumentComponent'
import SIUploadInformationComponent from '../../components/SIUploadInformationComponent'
import FrbUtils from 'src/views/freightbooking/shared/Utils'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import EditSIButtonComponent from 'src/views/shippinginstruction/components/EditSIButtonComponent'
import SIPaymentInstructionComponent from '../../components/SIPaymentInstructionComponent'
import SIScheduleComponent from '../../components/SIScheduleComponent'
import SIShipmentComponent from '../../components/SIShipmentComponent'
import SICargoComponent from '../../components/SICargoComponent'
import Utils from 'src/views/common/utils/Utils'
import ViewScheduleComponent from 'src/views/freightbooking/components/ViewScheduleComponent'
import WarningDialog from 'src/components/dialog/WarningDialog'
import { v4 as uuid } from 'uuid'
import GeneralStepperComponent from 'src/views/common/ui/GeneralStepperComponent'
import pathMap from 'src/paths/PathMap_ShippingInstruction'
import * as qs from 'query-string'
import ContactStatusFilterComponent from 'src/views/company-preference/addressbook/components/filters/ContactStatusFilterComponent'
import SIApi from 'src/views/shippinginstruction/shared/api'
import SIDGCargoComponent from '../../components/SIDGCargoComponent'
import QuickScroll from 'src/components/iconbutton/QuickScroll'

import SICargoUploadDialog from '../../components/SICargoUploadDialog'

import moment from 'moment'

import { useFormContext } from 'react-hook-form'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  table: { useDefaultNotification },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  // onsubmit validation works only if field error plus touched, touched is determined by fields(priority) and initialValues
  //saveDraft: true,
  bookingId: '',
  setFreightBookingId: '',
  bookingStatus: '',
  directSi: false,
  ...SIBookingTypeComponent.initialValues,
  ...SIScheduleComponent.initialValues,
  ...SIShipmentComponent.initialValues,
  ...SIContactDetailsComponent.initialValues,
  ...SIContainerComponent.initialValues,
  ...SICargoComponent.initialValues,
  ...SIPaymentInstructionComponent.initialValues
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
  // makeValidationSchema: DEFAULT_VALIDATION_SCHEMA
  //makeValidationSchema: makeValidationSchema}
}

const { CodeMaintenanceType } = constants

function FormFields({
  id,
  dockey,
  disabled,
  showNotification,
  loading,
  prevMenu,
  setLoading,
  recalculate,
  onSubmitForm,
  validationSchema
}) {
  const { errors, touched, resetForm } = useFormikContext()
  const history = useHistory()
  const location = useLocation()
  const stateData = location.state

  const [booking, setBooking] = useState({})
  const [uploadedSIList, setUploadedSIList] = useState([])
  const fbTranslatedTextsObject = FreightBookingTranslationText()
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const { setValue, reset, getValues, setError } = useFormContext()
  const [, , { setValue: setIsPilParty }] = useField('isPilParty')
  const [isPilPartyNewSi, setIsPilPartyNewSi] = useState(true)

  //const [saveDraftField, , { setValue: setSaveDraft }] = useField('saveDraft')
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [warningDialogOpen, setWarningDialogOpen] = useState(false)
  const [switchDGDialogOpen, setSwitchDGDialogOpen] = useState(false)
  const [siCargoUploadDialogOpen, setSiCargoUploadDialogOpen] = useState(false)
  const [dgCargo, setDGCargo] = useState(false)
  const [showLocalCategory, setShowLocalCategory] = useState(false)
  const [isDirectSi, setIsDirectSi] = useState(false)
  const [siStatus, setSiStatus] = useState(null)
  const [pendingDoc, setPendingDoc] = useState([])
  const [reloadSIData, setReloadSIData] = useState(false)
  const [tempUploadCargoPendingFile, setTempUploadCargoPendingFile] = useState(
    []
  )

  //const [fromSIReview, setFromSIReview] = useState(false)
  // const [isDirectSIField, , { setValue: setIsDirectSIField }] =
  //   useField('directSI')
  const [cargoKeyRef, setCargoKeyRef] = useState(uuid())

  const [, , { setValue: setBookingId }] = useField('bookingId')
  const [, , { setValue: setFreightBookingId }] = useField('freightBookingId')

  //move this from BookingTypeComponent to share with Cargo component
  const [hazardousField, , { setValue: setHazardousField }] =
    useField('hazardous')

  //move this from ShipmentComponent to handle onChange in ScheduleComponent
  const [placeOfReceiptCodeField, , { setValue: setPlaceOfReceiptCodeField }] =
    useField('placeOfReceiptCode')
  const [
    placeOfDeliveryCodeField,
    ,
    { setValue: setPlaceOfDeliveryCodeField }
  ] = useField('placeOfDeliveryCode')
  const [placeOfReceiptField, , { setValue: setPlaceOfReceiptField }] =
    useField('placeOfReceipt')
  const [placeOfDeliveryField, , { setValue: setPlaceOfDeliveryField }] =
    useField('placeOfDelivery')
  const [cargoDeliveryDateField, ,] = useField('cargoDeliveryDate')

  const placeOfReceiptKeyRef = useRef(uuid())
  const placeOfDeliveryKeyRef = useRef(uuid())
  const polKeyRef = useRef(uuid())
  const podKeyRef = useRef(uuid())
  const paymentKeyRef = useRef(uuid())
  const countryCodeByPortCodeRef = useRef([])
  const isEdit = useRef(false)
  console.log(stateData)

  const { fetchRecords } = useServices()
  const { submitForm, setFieldValue } = useFormikContext()
  const [shouldRender, setShouldRender] = useState(false)
  const [reeferError, setReeferError] = useState()

  const setValidatedError = (errorPath, message) => {
    let finalError = null
    let errorAttr = ''
    if (errorPath.includes(".")) {
      let errorObj = {}
      const arrErrorPath = errorPath.split(".")
      errorObj[arrErrorPath[1]] = message
      if (arrErrorPath[0].includes("[")) {
        const matches = arrErrorPath[0].match(/\[(.*?)\]/)
        if (matches) {
          errorAttr = arrErrorPath[0].replace(matches[0], "")
          const number = matches[1]
          finalError = []
          for (let i = 0; i < number; i++) {
            finalError.push(null)
          }
          finalError.push(errorObj)
        }
      } else {
        errorAttr = arrErrorPath[0]
        finalError = errorObj
      }
    } else {
      errorAttr = errorPath
      finalError = { message: message }
    }
    if (errorAttr && finalError) {
      setError(errorAttr, finalError)
    }

    //For reefer validation when reefer mandatory details are not entered and submit button is clicked
    if (errorPath.indexOf('shippingInfoContainerBoxes') !== -1 && errorPath.indexOf('equipmentTemp')) {
      let matches = errorPath.match(/\[(.*?)\]/);

      if (matches) {
        let submatch = matches[1];
        if (submatch != undefined) {
          setReeferError(submatch)
        }
      }
    }
    else {
      setReeferError(undefined)
    }
  }

  const onConfirmReview = async () => {

    await setFieldValue('saveDraft', false)
    await setFieldValue(
      'directSi',
      Utils.isEmpty(isDirectSi) ? false : isDirectSi
    )
    //submitForm()
    if (onSubmitForm) {
      const values = getValues()
      try {
        const validatedData = await validationSchema.validate(values)
        onSubmitForm(validatedData)
      } catch (error) {
        console.log('error', error);
        setValidatedError(error.path, error.message)
        setWarningDialogOpen(true)
      }
    }
  }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0
  }

  const onSaveDraft = async () => {

    await setFieldValue('saveDraft', true)
    await setFieldValue('directSi', isDirectSi)
    // submitForm()
    if (onSubmitForm) {
      const values = getValues()
      try {
        const validatedData = await validationSchema.validate(values)
        onSubmitForm(validatedData)
      } catch (error) {
        console.log('error', error);
        setValidatedError(error.path, error.message)
        setWarningDialogOpen(true)
      }
    }
  }

  const onDiscard = () => {
    setConfirmDialogOpen(true)
  }

  const discardSI = () => {
    //if prev menu is new si, return to new si
    //otherwise return to my si

    if (prevMenu === SIUtils.Menus.mySI) {
      history.push({
        pathname: pathMap.MY_SI
      })
    } else {
      history.push({
        pathname: pathMap.CREATE_SI,
        state: { shipmentClicked: pathMap === SIUtils.Menus.draftSI }
      })
    }
  }
  useEffect(() => {
    console.log('new si!')

    console.log(location.search)

    console.log(stateData)

    if (stateData != null && stateData.shippingInfo != null) {
      console.log(' this is an existing si')
      if (stateData && stateData.prevPage === SIUtils.Page.SIList) {
        console.log('getSIfromapi')
        setLoading(true)
        const siRequest = {
          shippingInfoId: stateData.shippingInfo.shippingInfoId,
          freightBookingId: stateData.shippingInfo.freightBookingId
        }
        SIApi.fetchSIDetailsById(
          fetchRecords,
          siRequest,
          populateBookingAndSIData
        )
      } else {
        console.log('getSIfromreview', stateData)
        setSiStatus(stateData.shippingInfo.siStatus)
        if (stateData != null && stateData.action === SIUtils.Action.copy) {
          setBooking(
            SIUtils.expandBookingToShippingInfo(stateData.shippingInfo)
          )
        } else {
          setBooking(stateData.shippingInfo)
        }
      }
    } else if (location.search !== '') {
      console.log('external url')
      const parsed = qs.parse(location.search)
      console.log(parsed)
      let bookingIdtset = parsed.id
      let dockeytest = parsed.dockey

      const bookingRequest = {
        bookingId: bookingIdtset,
        dockey: dockeytest
      }
      Api.fetchBookingDetailsById(
        fetchRecords,
        bookingRequest,
        populateBookingData
      )
    } else if (id && dockey != null) {
      console.log('getSIfromURL')
        setLoading(true)
        const siRequest = {
          shippingInfoId: id,
          freightBookingId: dockey
        }
        SIApi.fetchSIDetailsById(
          fetchRecords,
          siRequest,
          (data) => {
            populateBookingAndSIData(data)
          },
          (error) => {
            // Handle the error here
            console.error("Error fetching SI details:", error)

            // Redirect to "/unauthorized"
            history.push("/unauthorized")
          }
        )
    } else {
      //url without parameter
      console.log('create si from booking')
      if (stateData && stateData.prevPage === FrbUtils.Page.BookingList) {
        console.log('getbookingfromapi')
        setLoading(true)
        const bookingRequest = {
          bookingId: stateData.booking ? stateData.booking.bookingId : '',
          dockey: stateData.booking ? stateData.booking.dockey : ''
        }
        Api.fetchBookingDetailsById(
          fetchRecords,
          bookingRequest,
          populateBookingData
        )
      } else if (stateData != null && stateData.booking != null) {
        console.log('getbookingfromreview')
        setBooking(preProcessBookingData(stateData.booking))
      } else {
        console.log('this is a direct si')
        var addDays = 30
        var cargoReadyDate = moment().format(Utils.UI_FORMAT_DATE)
        var cargoDeliveryDate = moment(
          new Date(
            new Date().setTime(
              new Date().getTime() + addDays * 24 * 60 * 60 * 1000
            )
          )
        ).format(Utils.UI_FORMAT_DATE)
        setFieldValue('cargoReadyDate', cargoReadyDate)
        setFieldValue('cargoDeliveryDate', cargoDeliveryDate)
        setIsDirectSi(true)
      }
    }
  }, [])

  function populateBookingAndSIData(siAndBookingData) {
    //need to expand the data and set the values in side shippinginfo.booking to upper level
    console.log("CHECK SI AND BK", siAndBookingData)

    if(siAndBookingData.siStatus != 5202 && siAndBookingData.siStatus != 5209 && siAndBookingData.siStatus != 5210 && siAndBookingData.siStatus != 5212)
    {
      history.push("/unauthorized")
    }else{
      setSiStatus(siAndBookingData.siStatus)
      setBooking(SIUtils.expandBookingToShippingInfo(siAndBookingData))
      setLoading(false)
    }
  }

  function populateUploadSIDocuments(data) {
    console.log('populateUploadSIDocuments', data)
    let uploadedSi = []
    data.content.map((e, i) => {
      let file = {
        filename: e.fileName,
        docId: e.id,
        filesize: e.fileSize,

        uploadSuccess: e.status == 'Success',
        errorMsg: '',
        responseFilename: e.responseFilename,
        version: e.version,
        createdDate: e.createdDate
      }
      uploadedSi.push(file)
    })

    console.log('uploadSIDocuments', uploadedSi)
    setFieldValue('uploadSIDocuments', uploadedSi)
    setUploadedSIList(uploadedSi)
  }

  function populateBookingData(bookingData) {
    setBooking(preProcessBookingData(bookingData))
    setLoading(false)
  }

  function preProcessBookingData(bookingData) {
    //1) as shipper/carrier remarks in booking and SI share the same field name, but the value could be different,
    //when loading booking for new si creation, need to shif the value in shipperRemarks and carrierRemarks
    bookingData.bookingShipperRemarks = bookingData.shipperRemarks
    bookingData.shipperRemarks = ''
    bookingData.bookingCarrierRemarks = bookingData.carrierRemarks
    bookingData.carrierRemarks = ''

    //2) pol terminal is converted to pol desc, pod terminal is converted to pod desc
    //point 2 is not valid any more as pol/pod desc and pol/pod terminal are not the same concept

    //3) carrier ref no is convert to bookingRefNo
    bookingData.bookingRefNo = bookingData.carrierRefNo

    //4) payment - freight term convert from "frtTerm" to "freightTerm", convert pmtLoc to paymentLocation
    bookingData.freightTerm = bookingData.frtTerm
    bookingData.paymentLocation = bookingData.pmtLoc

    //5) notifiy me check box should be unchecked.
    bookingData.notifyPartnerEmails = false

    return bookingData
  }

  function populateBookingFromDirect(bookingData) {
    //hmm i think this will be no longer needed
    setBooking(preProcessBookingData(bookingData))
    resetForm()
    setFieldValue('directSI', false)
    setIsDirectSi(false)
  }

  useEffect(() => {
    console.log('booking changed', booking)
    //  if (!isEmpty(booking) || isDirectSi) {
    placeOfReceiptKeyRef.current = uuid()
    placeOfDeliveryKeyRef.current = uuid()
    polKeyRef.current = uuid()
    podKeyRef.current = uuid()
    paymentKeyRef.current = uuid()

    // if(data){
    //     setFieldValue('hazardous', data.hazardous)
    // }

    // if(data){
    //     console.log(JSON.stringify(data))
    //     setFieldValue('shipmentType', data.shipmentType)
    //     setFieldValue('moveType', data.moveType)
    // }
    PopulateDataFromReview()
    //   setShouldRender(true)
    // }
  }, [booking])

  function PopulateDataFromReview() {
    console.log('populateDataFromReview(): booking : ', booking)
    //as shipper remarks and carrier remarks share the same field name but can contain different value in booking and si
    //need to shuffle the name before populate value

    if (booking != null && !isEmpty(booking)) {
      console.log(
        'populate data representativeName ' + booking.representativeName
      )
      const dataArr = Object.entries(booking)
      dataArr.forEach(([key, val]) => {
        // console.log(key, val)
        if (SIUtils.dateTimeFields.includes(key)) {
          val = Utils.formatString(val, Utils.UI_FORMAT_DATE_TIME)
        } else if (SIUtils.dateFields.includes(key)) {
          val = Utils.formatString(val, Utils.UI_FORMAT_DATE)
        }

        //as we are using useArrayField for cargo/dg cargo, the setFieldValue cannot be null, hence need to convert null to [] for them
        else if (SIUtils.nonNullableArrayFields.includes(key)) {
          val = val === null ? [] : val
        }

        booking[key] = val
      })

      //no of originals is null when create new si from booking, however need to set it to default value so that value will be displayed in UI
      booking.noOfOriginals = Utils.isEmpty(booking.noOfOriginals)
        ? SIContactDetailsComponent.initialValues.noOfOriginals
        : booking.noOfOriginals


      setPendingDoc(booking.pendingShippingInfoDocuments)

      resetCurrentForm(booking)

      setIsPilParty(SIUtils.isThisPartyPIL(booking.carrierName))




      setDGCargo(booking.hazardous)
      setShowLocalCategory(portsIncludeSG())

      setIsDirectSi(booking != undefined ? booking.directSi : stateData.directSI)

      // setShouldRender(true)
      if (
        prevMenu === SIUtils.Menus.newSI &&
        !stateData.fromSIReview &&
        stateData.action !== SIUtils.Action.copy
      ) {
        isEdit.current = false
      } else {
        isEdit.current = true
      }
    }
  }

  // if (!shouldRender) {
  //   return null
  // }

  function resetCurrentForm(data) {
    console.log('reset', data)
    reset(data)
  }
  function portsIncludeSG() {
    return booking.polCode == 'SGSIN' || booking.podCode == 'SGSIN'
  }

  const onDGchange = (event) => {
    console.log(event.target.checked)

    setSwitchDGDialogOpen(true)
  }

  const switchDG = async () => {
    setDGCargo(!dgCargo)
    setHazardousField(!dgCargo)
    setSwitchDGDialogOpen(false)
  }

  const closeUploadCargoDialog = () => {
    setSiCargoUploadDialogOpen(false)
    setReloadSIData(!reloadSIData)
  }

  const refresh = () => {
    //alert('refresh')
    // setFieldValue('shippingInfoCargoes', [])
    //reset({ shippingInfoCargoes: [] })
    setReloadSIData(!reloadSIData)
    //setShouldRender(true)
  }

  const newSISteps = [
    siTranslatedTextsObject.stepLabelFillupDetails,
    siTranslatedTextsObject.stepLabelReviewDetails,
    siTranslatedTextsObject.stepLabelSubmit
  ]

  return (
    <Box id="top-level-box">
      {/* {'Error :' + JSON.stringify(errors)} */}
      <br />
      {/*  {'Touched :' + JSON.stringify(touched)}
      {'prevMenu :' + prevMenu} */}
      {/* {'isEdit2 :' + isEdit.current} */}

      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <CngBackdrop loading={loading} />
      </CngGridItem>

      <Paper>
        <Box alignItems='center' pr={15} pl={15}>
          <GeneralStepperComponent steps={newSISteps} activeStep={0} />
        </Box>

        <Box p={5} className='page-content'>
          <Box>
            <SIBookingTypeComponent.FormBody
              isDirectSI={isDirectSi}
              populateBookingFromDirect={populateBookingFromDirect}
              onDGchange={onDGchange}
              bookingTypeData={booking}
              isEdit={isEdit.current}
              prevMenu={prevMenu}
              showNotification={showNotification}
            />
          </Box>

          <Box pt={5}>
            <SIScheduleComponent.FormBody
              isDirectSI={isDirectSi}
              placeOfReceiptKeyRef={placeOfReceiptKeyRef}
              placeOfDeliveryKeyRef={placeOfDeliveryKeyRef}
              countryCodeByPortCodeRef={countryCodeByPortCodeRef}
              setPlaceOfReceiptCodeField={setPlaceOfReceiptCodeField}
              setPlaceOfDeliveryCodeField={setPlaceOfDeliveryCodeField}
              setPlaceOfReceiptField={setPlaceOfReceiptField}
              setPlaceOfDeliveryField={setPlaceOfDeliveryField}
              isEdit={isEdit.current}
              polKeyRef={polKeyRef.current}
              podKeyRef={podKeyRef.current}
              setShowLocalCategory={setShowLocalCategory}
              forceExpand={isDirectSi}
              prevMenu={prevMenu}
              setIsPilPartyNewSi={setIsPilPartyNewSi}
            />
          </Box>

          <Box pt={5}>
            <SIContactDetailsComponent.FormBody
              isDirectSI={isDirectSi}
              isEdit={isEdit.current}
              showNotification={showNotification}
              isPilPartyNewSi={isPilPartyNewSi}
            />
          </Box>

          <Box pt={5}>
            <SIShipmentComponent.FormBody
              placeOfReceiptKeyRef={placeOfReceiptKeyRef.current}
              placeOfDeliveryKeyRef={placeOfDeliveryKeyRef.current}
              countryCodeByPortCodeRef={countryCodeByPortCodeRef}
              placeOfReceiptCodeField={placeOfReceiptCodeField}
              setPlaceOfReceiptField={setPlaceOfReceiptField}
              setPlaceOfDeliveryField={setPlaceOfDeliveryField}
              placeOfDeliveryCodeField={placeOfDeliveryCodeField}
              isEdit={isEdit.current}
              isDirectSI={isDirectSi}
              prevMenu={prevMenu}
            />
          </Box>
          <Box pt={5}>
            <SIContainerComponent.FormBody
              //fromSIReview={fromSIReview}
              isDirectSI={isDirectSi}
              showNotification={showNotification}
              isEdit={isEdit.current}
              reloadSIData={reloadSIData}
              reeferError={reeferError}
            />
          </Box>

          {dgCargo ? (
            <Box pt={5}>
              <SIDGCargoComponent.FormBody
                isEdit={isEdit.current}
                showLocalCategory={showLocalCategory}
                cargoKeyRef={cargoKeyRef}
                isDirectSI={isDirectSi}
                recalculate={recalculate}
              />
            </Box>
          ) : (
            <Box pt={5}>
              <SICargoComponent.FormBody
                // fromSIReview={fromSIReview}
                handleSICargoUploadDialog={setSiCargoUploadDialogOpen}
                isDirectSI={isDirectSi}
                showNotification={showNotification}
                isEdit={isEdit.current}
                shippingInfoId={booking.shippingInfoId}
                reloadSIData={reloadSIData}
                shouldRender={shouldRender}
                setShouldRender={setShouldRender}
              />
            </Box>
          )}

          <Box pt={5}>
            <SIDocumentComponent.FormBody
              showNotification={showNotification}
              isEdit={isEdit.current}
              isDirectSI={isDirectSi}
              pendingDoc={pendingDoc}
            />
          </Box>

          {booking.shippingInfoId != undefined && !dgCargo && (
            <Box pt={5}>
              <SIUploadInformationComponent.FormBody
                showNotification={showNotification}
                isEdit={isEdit.current}
                shippingInfo={booking}
                updateShippingInfo={setBooking}
                uploadedSIList={uploadedSIList}
                updateSIList={setUploadedSIList}
                resetCurrentForm={resetCurrentForm}
                refresh={refresh}
                setShouldRender={setShouldRender}
              />
            </Box>
          )}

          <Box pt={5}>
            <SIPaymentInstructionComponent.FormBody
              isEdit={isEdit.current}
              forceExpand
              paymentKeyRef={paymentKeyRef.current}
            />
          </Box>
        </Box>
      </Paper>
      <Paper className='sticky-footer'>
        <Box alignItems='center'>
          <EditSIButtonComponent
            prevMenu={prevMenu}
            siStatus={siStatus}
            onSaveDraft={onSaveDraft}
            onConfirmReview={onConfirmReview}
            onDiscard={onDiscard}
          />
        </Box>
      </Paper>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={discardSI}
        content={siTranslatedTextsObject.dialogDiscardSIContent}
        okMsg={uiTranslatedTextsObject.ok}
        cancelMsg={uiTranslatedTextsObject.cancel}
        title={siTranslatedTextsObject.dialogDiscardSITitle}
      />

      <WarningDialog
        isDialogOpen={warningDialogOpen}
        closeDialog={() => setWarningDialogOpen(false)}
        okDialog={() => setWarningDialogOpen(false)}
        content={fbTranslatedTextsObject.formErrorWarning}
        okMsg={uiTranslatedTextsObject.ok}
      />

      <ConfirmDialog
        isConfirmDialogOpen={switchDGDialogOpen}
        closeDialog={() => setSwitchDGDialogOpen(false)}
        confirmDialog={switchDG}
        content={fbTranslatedTextsObject.dialogSwitchDGContent}
        okMsg={uiTranslatedTextsObject.ok}
        cancelMsg={uiTranslatedTextsObject.cancel}
        title={<h3>{uiTranslatedTextsObject.warning}</h3>}
      />

      <SICargoUploadDialog
        isDialogOpen={siCargoUploadDialogOpen}
        closeDialog={setSiCargoUploadDialogOpen}
        showNotification={showNotification}
        title={siTranslatedTextsObject.uploadCargo}
        shippingInfo={booking}
        setShippingInfo={setBooking}
        setPageLoading={setLoading}
        tempUploadCargoPendingFile={tempUploadCargoPendingFile}
        setTempUploadCargoPendingFile={setTempUploadCargoPendingFile}
        closeAndUpdateSI={closeUploadCargoDialog}
        refresh={refresh}
        setShouldRender={setShouldRender}
      />
      <QuickScroll />
    </Box>
  )
}

const NewSIFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
  // toClientDataFormat: toClientDataFormat,
  // toServerDataFormat: toServerDataFormat
})

export default NewSIFormProperties
