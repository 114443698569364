import { Box, Button, Grid } from '@material-ui/core'
import * as calistaConstant from 'src/constants/CalistaConstant'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import React from 'react'
import HtmlTooltip from 'src/views/freightbooking/components/HtmlTooltip'
import ShippingInstructionTranslationText from '../ShippingInstructionTranslationText'
function ManageSIReviewSIButtonComponent(props) {
  const translatedTextsObject = CalistaUiComponentTranslationText()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()

  function getLabelForBlueButton() {
    if (
      props.siStatus.toString() ===
      calistaConstant.SI_STATUS_PENDING_CARRIER_CONFIRMATION
    ) {
      if (props.isSkipManagerApproval) {
        return translatedTextsObject.apporveDischardChanges
      } else {
        return translatedTextsObject.confirm
      }
    } else {
      return translatedTextsObject.submitForApproval
    }
  }
  return (
    <Box pl={5} pr={5} pt={4} pb={4}>
      <Grid container xs={12} sm={12}>
        <Grid xs={6} sm={6} justify='flex-start'>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              props.onDiscard()
            }}
            classes={{ root: 'ng-button-filled-secondary' }}
          >
            {translatedTextsObject.discard}
          </Button>
        </Grid>
        <Grid container xs={6} sm={6} justify='flex-end' spacing={2}>
          <Box pr={2}>
            <Button
              variant='contained'
              color='secondary'
              className='button-blue originalText'
              onClick={() => {
                props.onEdit()
              }}
              classes={{ root: 'ng-button-filled-secondary' }}
            >
              {translatedTextsObject.back}
            </Button>
          </Box>
          <Box pr={2}>
            <HtmlTooltip
              title={
                props.siStatus.toString() ===
                calistaConstant.SI_STATUS_PENDING_CARRIER_CONFIRMATION
                  ? siTranslatedTextsObject.confirmWithoutChangeCaption
                  : ''
              }
            >
              <Button
                variant='contained'
                className='button-blue originalText'
                onClick={() => {
                  props.onSubmitSI()
                }}
              >
                {getLabelForBlueButton()}
              </Button>
            </HtmlTooltip>
          </Box>
          <Box>
            <Button
              variant='contained'
              className='button-light-green originalText'
              onClick={() => {
                props.onSaveDraft()
              }}
            >
              {translatedTextsObject.submitForConfirmation}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ManageSIReviewSIButtonComponent
