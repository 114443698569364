import { Box, Button, Grid } from '@material-ui/core'

import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import React from 'react'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
function SubmittedSIButtonComponent(props) {
  const translatedTextsObject = ShippingInstructionTranslationText()

  return (
    <Box pl={5} pr={5} pt={4} pb={4}>
      <Grid container xs={12} sm={12}>
        <Grid container xs={12} sm={12} justify='flex-end' spacing={2}>
          <Box>
            <Button
              variant='contained'
              className='button-blue originalText'
              onClick={() => {
                props.buttonAction()
              }}
            >
              {props.prevMenu === SIUtils.Menus.manageSI
                ? translatedTextsObject.manageShippingInstructionsTitle
                : translatedTextsObject.manageMySI}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SubmittedSIButtonComponent
